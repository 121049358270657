/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserBreakdown
 */
export interface UserBreakdown {
    /**
     * 
     * @type {string}
     * @memberof UserBreakdown
     */
    userRequesting?: string;
    /**
     * 
     * @type {number}
     * @memberof UserBreakdown
     */
    totalQtyRequested?: number;
    /**
     * 
     * @type {number}
     * @memberof UserBreakdown
     */
    numberOfRequests?: number;
    /**
     * 
     * @type {number}
     * @memberof UserBreakdown
     */
    totalValue?: number;
}

/**
 * Check if a given object implements the UserBreakdown interface.
 */
export function instanceOfUserBreakdown(value: object): value is UserBreakdown {
    return true;
}

export function UserBreakdownFromJSON(json: any): UserBreakdown {
    return UserBreakdownFromJSONTyped(json, false);
}

export function UserBreakdownFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserBreakdown {
    if (json == null) {
        return json;
    }
    return {
        
        'userRequesting': json['userRequesting'] == null ? undefined : json['userRequesting'],
        'totalQtyRequested': json['totalQtyRequested'] == null ? undefined : json['totalQtyRequested'],
        'numberOfRequests': json['numberOfRequests'] == null ? undefined : json['numberOfRequests'],
        'totalValue': json['totalValue'] == null ? undefined : json['totalValue'],
    };
}

export function UserBreakdownToJSON(value?: UserBreakdown | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userRequesting': value['userRequesting'],
        'totalQtyRequested': value['totalQtyRequested'],
        'numberOfRequests': value['numberOfRequests'],
        'totalValue': value['totalValue'],
    };
}

