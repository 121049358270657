/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NotProcessedReason
 */
export interface NotProcessedReason {
    /**
     * 
     * @type {string}
     * @memberof NotProcessedReason
     */
    reason?: string;
    /**
     * 
     * @type {number}
     * @memberof NotProcessedReason
     */
    count?: number;
}

/**
 * Check if a given object implements the NotProcessedReason interface.
 */
export function instanceOfNotProcessedReason(value: object): value is NotProcessedReason {
    return true;
}

export function NotProcessedReasonFromJSON(json: any): NotProcessedReason {
    return NotProcessedReasonFromJSONTyped(json, false);
}

export function NotProcessedReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): NotProcessedReason {
    if (json == null) {
        return json;
    }
    return {
        
        'reason': json['reason'] == null ? undefined : json['reason'],
        'count': json['count'] == null ? undefined : json['count'],
    };
}

export function NotProcessedReasonToJSON(value?: NotProcessedReason | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'reason': value['reason'],
        'count': value['count'],
    };
}

