import { Box, FormControl, MenuItem } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { Company, User } from "../../services/models";
import { Services } from "../../Services";
import { Global } from "../../Global";
import ALNToggleBtn from "../../components/form/ALNBtn/ALNToggleBtn";
import ALNCancelBtn from "../../components/form/ALNBtn/ALNCancelBtn";
import ALNModalTitle from "../../components/modal/ALNModalTitle";
import ALNModal from "../../components/modal/ALNModal";
import ALNModalHeader from "../../components/modal/ALNModalHeader";
import ALNInput from "../../components/form/ALNInput/ALNInput";
import ALNFormControlLabel from "../../components/form/ALNFormControlLabel/ALNFormControlLabel";
import ALNLabel from "../../components/label/ALNLabel";
import ALNBtn from "../../components/form/ALNBtn/ALNBtn";
import { ALNColors } from "../../utils/color";
interface NewUserModalProps {
  open: boolean;
  onClose: () => void;
  user?: User;
  isEdit: boolean;
}

export function NewUserModal(props: NewUserModalProps): JSX.Element {
  const [user, setUser] = useState<User>({});
  const [userCompanies, setUserCompanies] = useState<string[]>([]);
  const [allCompanies, setAllCompanies] = useState<Company[]>([]);
  const [errors, setErrors] = useState<string>("");

  useEffect(() => {
    if (props.user) {
      setUser(props.user);
      fetchCompanies();
    } else
      Services.userService
        .createUserData(Global.getJwtToken()!)
        .then((data) => {
          setUser(data);
        });
  }, [props.user]);

  const fetchCompanies = () => {
    if (user.emailAddress) {
      Services.companyService
        .getCompaniesForUser(Global.getJwtToken()!, user.emailAddress!)
        .then((companies) => {
          if (companies) setUserCompanies(companies);
        });
    }

    Services.companyService
      .getAllCompanies(Global.getJwtToken()!)
      .then((companies) => {
        if (companies) setAllCompanies(companies);
      });
  };

  useEffect(() => {
    fetchCompanies();
  }, [props.user]);

  const handleSubmit = () => {
    if (userCompanies.length == 0 && props.isEdit) {
      setErrors("Please add company.");
      setTimeout(() => setErrors(""), 4000);
      console.log(errors);
      return;
    }

    Services.userService.saveUser(Global.getJwtToken()!, user);
    handleClose();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleClose = () => {
    Services.userService.createUserData(Global.getJwtToken()!).then((data) => {
      setUser(data);
    });
    props.onClose();
  };

  return (
    <ALNModal open={props.open} onClose={props.onClose}>
      <ALNModalHeader>
        <ALNModalTitle title={props.isEdit ? "Update User" : "New User"} />
        <ALNCancelBtn onClick={handleClose} />
      </ALNModalHeader>
      <Box
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
        sx={{
          paddingLeft: "32px",
          paddingRight: "32px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "525px",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "1px",
            },
            "& fieldset": {
              margin: 0,
            },
            "& .MuiFormControl-root": {
              width: "100%",
              margin: 0,
            },
          }}
        >
          <ALNLabel variant="subtitle2" sx={{ marginTop: "20px" }}>
            Edit Mail
          </ALNLabel>
          <ALNInput
            id="emailAddress"
            name="emailAddress"
            value={user.emailAddress}
            label="E-Mail"
            type="email"
            onChange={props.isEdit ? () => {} : handleChange}
          />
          <ALNLabel variant="subtitle2" sx={{ marginTop: "20px" }}>
            Edit FirstName
          </ALNLabel>
          <ALNInput
            id="firstName"
            name="firstName"
            value={user.firstName}
            label="First Name"
            onChange={handleChange}
          />
          <ALNLabel variant="subtitle2" sx={{ marginTop: "20px" }}>
            Edit LastName
          </ALNLabel>
          <ALNInput
            id="lastName"
            name="lastName"
            value={user.lastName}
            label="Last Name"
            onChange={handleChange}
          />
          <ALNLabel variant="subtitle2" sx={{ marginTop: "20px" }}>
            Edit Password
          </ALNLabel>
          <ALNInput
            id="password"
            name="password"
            value={user.password}
            label={
              props.isEdit ? "Set New User Password" : "Password (Required)"
            }
            onChange={handleChange}
            required={!props.isEdit}
          />
          <Box>
            <FormControl
              component="fieldset"
              margin="normal"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                "& .Mui-focused .new-user-modal__input-label": {
                  color: ALNColors.primary_btn,
                },
              }}
            >
              <ALNLabel
                sx={{
                  position: "static",
                  transform: "none",
                  fontFamily: '"OpenSans", sans-serif',
                  fontSize: "18px",
                  fontWeight: 600,
                  lineHeight: "24px",
                  color: ALNColors.green,
                  letterSpacing: "0.02em",
                  marginTop: "25px",
                  marginBottom: "0px",
                  padding: 0,
                }}
              >
                Active
              </ALNLabel>
              <ALNToggleBtn
                active={user.isActive}
                firstToggle="yes"
                secondToggle="no"
                onChange={(value) => {
                  setUser({ ...user, isActive: value === "yes" });
                }}
              />
            </FormControl>
          </Box>
          <Box>
            <FormControl
              component="fieldset"
              fullWidth
              margin="normal"
              sx={{
                borderRadius: 1,
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                "& .Mui-focused .new-user-modal__input-label": {
                  color: ALNColors.primary_btn,
                },
              }}
            >
              <ALNLabel
                sx={{
                  position: "static",
                  transform: "none",
                  fontFamily: '"OpenSans", sans-serif',
                  fontSize: "18px",
                  fontWeight: 600,
                  lineHeight: "24px",
                  color: ALNColors.green,
                  letterSpacing: "0.02em",
                  marginTop: "25px",
                  marginBottom: "0px",
                  padding: 0,
                }}
              >
                Permissions
              </ALNLabel>
              <ALNFormControlLabel
                control={
                  <ALNToggleBtn
                    active={user.canAdministerUsers}
                    firstToggle="yes"
                    secondToggle="no"
                    onChange={(value) => {
                      setUser({ ...user, canAdministerUsers: value === "yes" });
                    }}
                  />
                }
                label="Can Administer Users"
                labelPlacement="start"
              />
              <ALNFormControlLabel
                control={
                  <ALNToggleBtn
                    active={user.canAdministerCompanies}
                    firstToggle="yes"
                    secondToggle="no"
                    onChange={(value) => {
                      setUser({
                        ...user,
                        canAdministerCompanies: value === "yes",
                      });
                    }}
                  />
                }
                label="Can Administer Companies"
                labelPlacement="start"
              />
              <ALNFormControlLabel
                control={
                  <ALNToggleBtn
                    active={user.canAdministerMailBoxes}
                    firstToggle="yes"
                    secondToggle="no"
                    onChange={(value) => {
                      setUser({
                        ...user,
                        canAdministerMailBoxes: value === "yes",
                      });
                    }}
                  />
                }
                label="Can Administer Mailboxes"
                labelPlacement="start"
              />
              <ALNFormControlLabel
                control={
                  <ALNToggleBtn
                    active={user.canRespondToEmails}
                    firstToggle="yes"
                    secondToggle="no"
                    onChange={(value) => {
                      setUser({ ...user, canRespondToEmails: value === "yes" });
                    }}
                  />
                }
                label="Can Respond To Emails"
                labelPlacement="start"
              />
              <ALNFormControlLabel
                control={
                  <ALNToggleBtn
                    active={user.canAdminsterEmailRules}
                    firstToggle="yes"
                    secondToggle="no"
                    onChange={(value) => {
                      setUser({
                        ...user,
                        canAdminsterEmailRules: value === "yes",
                      });
                    }}
                  />
                }
                label="Can Administer Email Rules"
                labelPlacement="start"
              />
              <ALNFormControlLabel
                control={
                  <ALNToggleBtn
                    active={user.salesDeskManager}
                    firstToggle="yes"
                    secondToggle="no"
                    onChange={(value) => {
                      setUser({
                        ...user,
                        salesDeskManager: value === "yes",
                      });
                    }}
                  />
                }
                label="Sales Desk Manager"
                labelPlacement="start"
              />

              <ALNFormControlLabel
                control={
                  <ALNToggleBtn
                    active={user.salesDeskUser}
                    firstToggle="yes"
                    secondToggle="no"
                    onChange={(value) => {
                      setUser({
                        ...user,
                        salesDeskUser: value === "yes",
                      });
                    }}
                  />
                }
                label="Sales Desk User"
                labelPlacement="start"
              />
            </FormControl>
          </Box>
          {props.isEdit && (
            <Box
              sx={{
                marginTop: "20px",
                marginBottom: "10px",
              }}
            >
              <ALNLabel variant="h6">User Companies</ALNLabel>
              {userCompanies && userCompanies.length > 0 ? (
                userCompanies.map((userCompany) => (
                  <Box
                    key={userCompany}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 1,
                    }}
                  >
                    <ALNLabel>{userCompany}</ALNLabel>
                    <ALNBtn
                      variant="outlined"
                      sx={{ color: ALNColors.secondary }}
                      onClick={() => {
                        Services.companyService
                          .removeUserFromCompany(
                            Global.getJwtToken()!,
                            user.emailAddress!,
                            userCompany,
                          )
                          .then(() => {
                            setUserCompanies((prevCompanies) =>
                              prevCompanies.filter(
                                (company) => company !== userCompany,
                              ),
                            );
                            setUser((prevUser) => ({
                              ...prevUser,
                            }));
                          });
                      }}
                    >
                      Delete
                    </ALNBtn>
                  </Box>
                ))
              ) : (
                <ALNLabel>No companies assigned</ALNLabel>
              )}
              <ALNInput
                value=""
                type="select"
                id="all-companies"
                name="all-companies"
                label="Add Company"
                required={false}
                onChange={(e) => {
                  const selectedCompany = allCompanies.find(
                    (c) => c.id === e.target.value,
                  );
                  if (selectedCompany) {
                    Services.companyService
                      .addUserToCompany(
                        Global.getJwtToken()!,
                        user.emailAddress!,
                        selectedCompany.companyName!,
                        selectedCompany.id,
                      )
                      .then(() => {
                        setUserCompanies((prevCompanies) => [
                          ...prevCompanies,
                          selectedCompany.companyName!,
                        ]);
                      });
                  }
                }}
              >
                {allCompanies
                  .filter(
                    (company) => !userCompanies.includes(company.companyName!),
                  )
                  .map((company) => (
                    <MenuItem key={company.id} value={company.id}>
                      {company.companyName}
                    </MenuItem>
                  ))}
              </ALNInput>
            </Box>
          )}
        </Box>

        {props.isEdit ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <ALNBtn
              variant="contained"
              type="submit"
              sx={{
                bgcolor: ALNColors.email_manual,
                mt: 2,
                width: "120px",
                mb: 2,
                ml: "auto",
                float: "inline-end",
              }}
              onClick={() => {}}
            >
              Submit
            </ALNBtn>
          </Box>
        ) : (
          <ALNBtn
            variant="contained"
            type="submit"
            sx={{
              bgcolor: ALNColors.email_manual,
              mt: 2,
              width: "120px",
              mb: 2,
              ml: "auto",
              float: "inline-end",
            }}
            onClick={() => {}}
          >
            Submit
          </ALNBtn>
        )}
      </Box>
    </ALNModal>
  );
}
