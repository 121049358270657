import React from "react";
import { ReactComponent as WelcomeUp } from "../assets/images/welcome-up.svg";
import { ReactComponent as WelcomeDown } from "../assets/images/welcome-down.svg";
import { Box } from "@mui/material";
import { ALNColors } from "../utils/color";
import ALNLabel from "../components/label/ALNLabel";

interface AuthLeftProps {
  title: string;
  description: string;
}

function AuthLeft({ title, description }: AuthLeftProps) {
  return (
    <Box>
      <WelcomeUp
        style = {{
          width: '90%',
          height: 'auto',
          maxWidth: '517px',
        }}
      />
      <ALNLabel
        sx={{
          margin: { xs: 0, sm: 0, md: 0, lg: 0, xl: '20px' },
          fontFamily: '"OpenSans"',
          fontSize: '64px',
          fontWeight: 700,
          lineHeight: '96px',
          letterSpacing: 0.02,
          textAlign: 'left',
          color: ALNColors.white,
        }}
      >{title}</ALNLabel>
      <ALNLabel
        sx={{  
          margin: { xs: 0, sm: 0, md: 0, lg: 0, xl: '20px' },
          fontFamily: '"OpenSans"',
          fontSize: '18px',
          fontWeight: 450,
          lineHeight: '27px',
          letterSpacing: 0.02,
          textAlign: 'left',
          fontVariationSettings: '"wdth" 100',
          color: ALNColors.gray_very_light}}
      >{description}
      </ALNLabel>
      <WelcomeDown 
        style={{
          width: '95%',
          height: 'auto',
          maxWidth: '672px',
      }} />
    </Box>
  );
}

export default AuthLeft;
