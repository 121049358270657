/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetErrorMessages200ResponseInner
 */
export interface GetErrorMessages200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetErrorMessages200ResponseInner
     */
    message: string;
    /**
     * 
     * @type {Date}
     * @memberof GetErrorMessages200ResponseInner
     */
    date: Date;
}

/**
 * Check if a given object implements the GetErrorMessages200ResponseInner interface.
 */
export function instanceOfGetErrorMessages200ResponseInner(value: object): value is GetErrorMessages200ResponseInner {
    if (!('message' in value) || value['message'] === undefined) return false;
    if (!('date' in value) || value['date'] === undefined) return false;
    return true;
}

export function GetErrorMessages200ResponseInnerFromJSON(json: any): GetErrorMessages200ResponseInner {
    return GetErrorMessages200ResponseInnerFromJSONTyped(json, false);
}

export function GetErrorMessages200ResponseInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetErrorMessages200ResponseInner {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['message'],
        'date': (new Date(json['date'])),
    };
}

export function GetErrorMessages200ResponseInnerToJSON(value?: GetErrorMessages200ResponseInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'message': value['message'],
        'date': ((value['date']).toISOString()),
    };
}

