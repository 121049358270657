import React from "react";
import { Email } from "../../../services/models/Email";
import { Box } from "@mui/material";
import EmailMainPart from "../../../components/EmailMainPart";
import { ALNColors } from "../../../utils/color";
interface AutoRespondedProps {
  emailData: Email;
  onClose: () => void;
}

function AutoResponded(props: AutoRespondedProps) {
  return (
    <Box
      sx={{
        borderRadius: 2,
        backgroundColor: ALNColors.white,
        flex: 1,
      }}
    >
      <EmailMainPart email={props.emailData} onClose={props.onClose} />
    </Box>
  );
}

export default AutoResponded;
