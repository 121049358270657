import Sidebar from "../frame/Sidebar";
import { Global, Pages } from "../Global";
import { ObserveState } from "../StateManagement/ObserveState";
import EmailList from "../pages/EmailList";
import { CompaniesList } from "../pages/CompaniesList";
import { MailboxList } from "../pages/MailboxList";
import { EmailAddressRulesList } from "../pages/EmailAddressRulesList";
import { UsersList } from "../pages/UsersList";
import QuoteTool from "../pages/QuoteTool";
import ParseTool from "../pages/ParseTool";
import Dashboard from "../pages/Dashboard";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { PortalInfo } from "../services/models/PortalInfo";
import Dashboard_Products from "../pages/Dashboard_Products";
import Dashboard_Reasons from "../pages/Dashboard_Reasons";
import Dashboard_UserProducts from "../pages/Dashboard_UserProducts";
import Dashboard_Users from "../pages/Dashboard_Users";
import { Support } from "../pages/Support";
import Settings from "../pages/Settings";

function RequireAuth() {
  const [portalInfo, setPortalInfo] = useState<PortalInfo | undefined>(
    undefined,
  );

  let token = Global.getJwtToken();

  useEffect(() => {
    Global.getPortalInfo().then((portalInfo) => {
      setPortalInfo(portalInfo);
    });
  }, [token]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "99vh",
        padding: "0px",
        margin: "0px",
      }}
    >
      <Sidebar />
      <Box
        sx={{
          width: "calc(100vw - 263px)",
          height: "100vh",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <ObserveState
          listenTo={Global.getGlobal()}
          control={() => {
            switch (Global.getGlobal().CurrentPage) {
              case Pages.Dashboard:
                return <Dashboard />;
              case Pages.DashboardReasons:
                return <Dashboard_Reasons />;
              case Pages.DashboardProducts:
                return <Dashboard_Products />;
              case Pages.DashboardCustomerProducts:
                return <Dashboard_UserProducts />;
              case Pages.DashboardCustomers:
                return <Dashboard_Users />;
              case Pages.EmailList:
                return (
                  <ObserveState
                    listenTo={Global.getGlobal()}
                    properties={["CurrentMailbox"]}
                    control={() => {
                      return (
                        <EmailList
                          mailboxName={Global.getGlobal().CurrentMailbox!}
                        />
                      );
                    }}
                  />
                );
              case Pages.UserList:
                return <UsersList />;
              case Pages.Companies:
                return <CompaniesList />;
              case Pages.MailBoxList:
                return <MailboxList />;
              case Pages.EmailAddressRules:
                return <EmailAddressRulesList />;
              case Pages.QuoteTool:
                return <QuoteTool />;
              case Pages.EmailParseTest:
                return <ParseTool />;

              case Pages.Support:
                return <Support />;
              case Pages.Settings:
                return <Settings />;
            }
          }}
        />
      </Box>
    </Box>
  );
}

export default RequireAuth;
