import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { QuotePercentage } from "../../services/models/QuotePercentage";
import {
  CardContent,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { ALNColors } from '../../utils/color';

interface PieChartProps {
  data: QuotePercentage[] | undefined;
  width: number;
  height: number;
}

function PieChart ({ data, width, height }: PieChartProps) {
  const ref = useRef<SVGSVGElement | null>(null);

  const matchLabel = (label: string) => {
    switch (label) {
      case "AutoProcessed":
        return "Auto";
      case "ManualProcessed":
        return "Manual";
      case "NotProcessed":
        return "Could Not Respond";
      case "Annotated":
        return "Annotated";
      case "Draft":
        return "Draft";
      case "Ignored":
        return "Ignored";
      default:
        return label;
    }
  };

  const matchColor = (label: string) => {
    switch (label) {
      case "AutoProcessed":
        return ALNColors.green_medium_light;
      case "ManualProcessed":
        return ALNColors.blue_light;
      case "NotProcessed":
        return ALNColors.brown_light_shade;
      case "Annotated":
        return ALNColors.gray_medium_light;
      case "Draft":
        return ALNColors.pink_very_light_shade;
      case "Ignored":
        return ALNColors.red_medium_shade;
      default:
        return ALNColors.red_medium_shade;
    }
  };

  const matchStatus = (status: string) => {
    switch (status) {
      case "AutoProcessed":
        return "Emails auto processed";
      case "ManualProcessed":
        return "Emails manual processed";
      case "NotProcessed":
        return "Emails not processed";
      case "Annotated":
        return "Emails annotated";
      case "Draft":
        return "Emails draft";
      case "Ignored":
        return "Emails ignored";
      default:
        return status;
    }
  };

  // const color = d3
  //   .scaleOrdinal<string>()
  //   .range([ALNColors.blue_light, ALNColors.red_medium_shade, ALNColors.green_medium_light, ALNColors.brown_light_shade, ALNColors.pink_very_light_shade, ALNColors.gray_medium_light]);

  useEffect(() => {
    const svg = d3
      .select(ref.current)
      .attr("width", width)
      .attr("height", height);
    svg.append("style").text(`
      .tooltip {
        font-size: 14px;
        pointer-events: none;
        transition: opacity 0.2s ease;
    
        font-family: Arial, sans-serif;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        border: 1px solid #C1C4C9;
        border-radius: 2px;
    
        .status {
            font-size: 10px;
            text-align: left;
            color: black;
            background-color: white;
            padding: 5px 5px 5px 5px;
        }
    
        .total {
            font-size: 12px;
            text-align: right;
            color: #3A4188;
            background-color: #C1C4C9;
            padding: 5px 5px 5px 5px;
        }
    
        .percentage {
            font-size: 12px;
            text-align: right;
            color: #3A4188;
            background-color: #C1C4C9;
            padding: 5px 5px 5px 5px;
        }
      }
    `);
    const radius = Math.min(width, height) / 2.5;

    const filterData = data?.map((d) => ({
      status: d.status,
      percentage: d.percentage,
      total: d.total,
    })) as { status: string; percentage: number; total: number }[];

    let minStatus = "";
    if (filterData === null || filterData.length === 0) {

    } else {
      minStatus = filterData.reduce(
        (minObj, current) =>
          current.percentage <= minObj.percentage ? current : minObj,
        filterData[0],
      ).status;
    }

    const pie = d3
      .pie<{ status: string; percentage: number; total: number }>()
      .value((d) => d.percentage);
    const arc = d3
      .arc<
        d3.PieArcDatum<{ status: string; percentage: number; total: number }>
      >()
      .innerRadius(0)
      .outerRadius(radius * 0.7);

    const outerArc = d3
      .arc<
        d3.PieArcDatum<{ status: string; percentage: number; total: number }>
      >()
      .innerRadius(radius * 0.7 + 10)
      .outerRadius(radius * 0.7 + 15);

    const backgroundArc = d3
      .arc<
        d3.PieArcDatum<{ status: string; percentage: number; total: number }>
      >()
      .innerRadius(radius * 0.7 + 15)
      .outerRadius(radius * 0.7 + 40);

    svg.selectAll("*").remove(); // Clear previous renders

    const g = svg
      .append("g")
      .attr("transform", `translate(${width / 2 + 30}, ${height / 2})`);

    // Tooltip Setup
    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", ALNColors.white)
      .style("pointer-events", "none")
      .style("box-shadow", "0 0 8px rgba(0,0,0,0.1)");

    // Background and Outer Arcs for Hover Effect
    const outerArcs = g
      .selectAll(".outerarc")
      .data(pie(filterData))
      .enter()
      .append("g")
      .style("pointer-events", "none")
      .attr("class", "outerarc");

    outerArcs
      .append("path")
      .attr("d", outerArc as any)
      .attr("id", (d) => {
        return `outerArc-${d.data.status}`;
      })
      // .style('pointer-events', 'none')
      .attr("fill", (d) => matchColor(d.data.status))
      .style("opacity", 0); // Initially hidden

    const backgroundArcs = g
      .selectAll(".backgroundarc")
      .data(pie(filterData))
      .enter()
      .append("g")
      .style("pointer-events", "none")
      .attr("class", "backgroundarc");

    backgroundArcs
      .append("path")
      .attr("d", backgroundArc as any)
      .attr("id", (d) => {
        return `backgroundArc-${d.data.status}`;
      })
      .style("opacity", 0); // Initially hidden

    const arcs = g
      .selectAll(".arc")
      .data(pie(filterData))
      .enter()
      .append("g")
      .style("pointer-events", "none")
      .attr("class", "arc");

    arcs
      .append("path")
      .attr("fill", (d) => matchColor(d.data.status))
      .transition()
      .duration(1000)
      .attrTween("d", function (d) {
        const i = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
        return (t) => arc(i(t))!;
      })
      .on("end", () => {
        arcs.style("pointer-events", "auto");
        outerArcs.style("pointer-events", "auto");
        backgroundArcs.style("pointer-events", "auto");
      });

    arcs
      .append("text")
      .attr("transform", (d) => {
        const [x, y] = arc.centroid(d);
        const offset = 1.0;
        // const offset = 2.5;
        return `translate(${x * offset}, ${y * offset})`;
      })
      .attr("text-anchor", (d) => {
        if (d.data.status === minStatus) return "left";
        if (arc.centroid(d)[0] < 0) return "end";
        return "left";
      })
      .attr("id", (d) => {
        return `pie-label-${d.data.status}`;
      })
      .attr("font-size", "14px")
      .text((d) => {
        if (d.data.percentage !== 0) return `${matchLabel(d.data.status)}`;
        else return ``;
      });

    arcs
      .append("text")
      .attr("transform", (d) => {
        const [x, y] = arc.centroid(d);
        const offset = 2.5;
        return `translate(${x * offset}, ${y * offset + 20})`;
      })
      .attr("text-anchor", (d) => {
        if (d.data.status === minStatus) return "left";
        if (arc.centroid(d)[0] < 0) return "end";
        return "left";
      })
      .attr("id", (d) => {
        return `pie-percentage-${d.data.status}`;
      })
      .attr("font-size", "14px")
      .text((d) => {
        if (d.data.percentage !== 0) return `${d.data.percentage}%`;
        else return ``;
      });

    // Hover Events
    arcs
      .on("mouseenter", function (event, d) {
        d3.select(this)
          .select("path")
          .transition()
          .duration(200)
          .attr("transform", "scale(1.02)"); // Slightly enlarge the slice

        // outerArcs.style('opacity', 1); // Show outer arcs
        d3.select(`#outerArc-${d.data.status}`).style("opacity", 0.7);

        // backgroundArcs.style('opacity', 1); // Show background arcs
        d3.select(`#backgroundArc-${d.data.status}`).style("opacity", 0.7);

        d3.select(`#pie-label-${d.data.status}`)
          .transition()
          .duration(200)
          .attr("fill", "red")
          .attr("font-size", "16px");
        d3.select(`#pie-percentage-${d.data.status}`)
          .transition()
          .duration(200)
          .attr("fill", "red")
          .attr("font-size", "16px");

        tooltip
          .style("opacity", 1)
          // .html(`${matchLabel(d.data.status)}: ${d.data.percentage}%`)
          .html(
            `<div class="status">${matchStatus(d.data.status)}</div><div class="total">${d.data.total.toLocaleString()}</div><div class="percentage">${d.data.percentage}%</div>`,
          )
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY - 20}px`);
      })
      .on("mousemove", (event) => {
        tooltip
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY - 20}px`);
      })
      .on("mouseleave", function (_, d) {
        d3.select(this)
          .select("path")
          .transition()
          .duration(200)
          .attr("transform", "scale(1)"); // Reset slice size

        d3.select(`#pie-label-${d.data.status}`)
          .transition()
          .duration(200)
          .attr("fill", ALNColors.black)
          .attr("font-size", "14px");
        d3.select(`#pie-percentage-${d.data.status}`)
          .transition()
          .duration(200)
          .attr("fill", ALNColors.black)
          .attr("font-size", "14px");

        // outerArcs.style('opacity', 0); // Hide outer arcs
        d3.select(`#outerArc-${d.data.status}`).style("opacity", 0);

        // backgroundArcs.style('opacity', 0); // Hide background arcs
        d3.select(`#backgroundArc-${d.data.status}`).style("opacity", 0);

        tooltip.style("opacity", 0);
      });
  }, [data, width, height]);

  return (
    <CardContent
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        height: height * 1.5,
      }}
    >
      <Box>
        <svg ref={ref} />
      </Box>
      <TableContainer
        component={Paper}
        sx={{ alignSelf: "center", borderRadius: "0px", width: "90%" }}
      >
        <Table>
          <TableBody sx={{ backgroundColor: ALNColors.white_green }}>
            {data?.map((row) => (
              <TableRow key={row.status}>
                <TableCell component="th" scope="row">
                  {matchLabel(row.status ?? "")}
                </TableCell>
                <TableCell align="center">
                  <Box
                    sx={{
                      width: "50px",
                      height: "20px",
                      borderRadius: "5px",
                      display: "block",
                      backgroundColor: matchColor(row.status ?? ""),
                    }}
                  />
                </TableCell>
                <TableCell align="right">{row.percentage}%</TableCell>
                <TableCell align="right">{row.total}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CardContent>
  );
};

export default PieChart;
