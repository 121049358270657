import React from "react";
import { Box } from "@mui/material";
interface ALNEmailBtnProps {
  onClick: () => void;
  sx?: object | undefined;
  children? : React.ReactNode;
}
function ALNEmailBtn ({ onClick, sx, children }: ALNEmailBtnProps) {
  
  return(
    <Box
      sx={sx}
      onClick={onClick}
    >
      {children}
    </Box>
  )
}
export default ALNEmailBtn;