/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductBreakdown
 */
export interface ProductBreakdown {
    /**
     * 
     * @type {string}
     * @memberof ProductBreakdown
     */
    productName?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductBreakdown
     */
    totalQtyRequested?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBreakdown
     */
    numberOfRequests?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductBreakdown
     */
    totalValue?: number;
}

/**
 * Check if a given object implements the ProductBreakdown interface.
 */
export function instanceOfProductBreakdown(value: object): value is ProductBreakdown {
    return true;
}

export function ProductBreakdownFromJSON(json: any): ProductBreakdown {
    return ProductBreakdownFromJSONTyped(json, false);
}

export function ProductBreakdownFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductBreakdown {
    if (json == null) {
        return json;
    }
    return {
        
        'productName': json['productName'] == null ? undefined : json['productName'],
        'totalQtyRequested': json['totalQtyRequested'] == null ? undefined : json['totalQtyRequested'],
        'numberOfRequests': json['numberOfRequests'] == null ? undefined : json['numberOfRequests'],
        'totalValue': json['totalValue'] == null ? undefined : json['totalValue'],
    };
}

export function ProductBreakdownToJSON(value?: ProductBreakdown | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'productName': value['productName'],
        'totalQtyRequested': value['totalQtyRequested'],
        'numberOfRequests': value['numberOfRequests'],
        'totalValue': value['totalValue'],
    };
}

