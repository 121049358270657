import { useEffect, useState } from "react";
import { EmailAddressRules } from "../services/models";
import { Services } from "../Services";
import { EmailAddressRulesItem } from "./emaliAddressRules/EmailAddressRulesItem";
import { EmailAddressRulesModal } from "./emaliAddressRules/EmailAddressRulesModal";
import { Box } from "@mui/material";
import { Global } from "../Global";
import { ALNColors } from "../utils/color";
import ALNBtn from "../components/form/ALNBtn/ALNBtn";
import ALNLabel from "../components/label/ALNLabel";

export function EmailAddressRulesList() {
  const [emailAddressRulees, setEmailAddressRulees] = useState<
    EmailAddressRules[]
  >([]);
  const [open, setOpen] = useState(false);
  const [editEmailAddressRule, setEditEmailAddressRule] =
    useState<EmailAddressRules>({
      addressWithWildcard: "",
      blockedReason: "",
    });

  const fetchEmailAddressRulees = () => {
    Services.emailAddressRuleService
      .getEmailAddressRules(Global.getJwtToken()!)
      .then((emails) => {
        setEmailAddressRulees(emails);
      });
  };

  const token = Global.getJwtToken();

  useEffect(() => {
    fetchEmailAddressRulees();
  }, [token]);

  const handleDeleteEmailAddressRule = (
    emailAddressRule: EmailAddressRules,
  ) => {
    Services.emailAddressRuleService
      .deleteEmailAddressRules(Global.getJwtToken()!, emailAddressRule.id)
      .then(() => {
        setEmailAddressRulees(
          emailAddressRulees.filter(
            (email) => email.id !== emailAddressRule.id,
          ),
        );
      });
  };

  const handleEditEmailAddressRule = (emailAddressRule: EmailAddressRules) => {
    setEditEmailAddressRule(emailAddressRule);
    setOpen(true);
  };

  const handleOpen = () => {
    setEditEmailAddressRule({
      addressWithWildcard: "",
      blockedReason: "",
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditEmailAddressRule({
      addressWithWildcard: "",
      blockedReason: "",
    });
    setTimeout(() => {
      fetchEmailAddressRulees();
    }, 200);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "25px 50px",
        background: "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)"
      }}
    >
      <ALNLabel sx={{fontSize: "10px", lineHeight: "12px", fontWeight: 500, width: "fit-content"}}>
        Email Rules
      </ALNLabel>
      <Box 
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '40px',
          marginBottom: '20px',
        }}
      >
        <ALNLabel sx={{fontSize: "40px", lineHeight: "50px", fontWeight: 400, width: "fit-content"}}>
          Email Rules
        </ALNLabel>
        <ALNBtn 
          variant="contained"
          sx={{ bgcolor: ALNColors.email_manual, height: 45 }}
          onClick={handleOpen}
          >
          <ALNLabel
            sx={{
              fontSize: '16px !important',
              fontWeight: 600,
              fontFamily: "'Open Sans', sans-serif",
              lineHeight: 1.5,
              color: ALNColors.white,
              letterSpacing: '2%',
              textTransform: 'capitalize',
            }}
          >
            Add Rule
          </ALNLabel>
        </ALNBtn>
      </Box>
      <Box sx={{
        background: ALNColors.white,
        padding: "16px", 
        borderRadius : "16px",
        border: "1px solid "+ALNColors.background_gray,
        flexGrow: 1,
        height: 'calc(100vh - 220px)',
      }}>
        <Box 
          sx={{ overflowY: 'auto', maxHeight: '100%' }}>
          {emailAddressRulees.map((emailAddressRule) => (
            <EmailAddressRulesItem
              key={emailAddressRule.id}
              emailAddressRule={emailAddressRule}
              onDelete={handleDeleteEmailAddressRule}
              openEditModal={handleEditEmailAddressRule}
            />
          ))}
        </Box>

        {emailAddressRulees.length === 0 && (
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"300px"}
          >
            No Email Rules
          </Box>
        )}
      </Box>
      <EmailAddressRulesModal
        open={open}
        onClose={() => handleClose()}
        emailAddressRule={editEmailAddressRule}
      />
    </Box>
  );
}
