import RequireAuth from "./layouts/RequireAuth";
import HasAuth from "./layouts/HasAuth";
import { ObserveState } from "./StateManagement/ObserveState";
import { Global } from "./Global";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    document.title = "Reply.ai";
  }, []);

  return (
    <ObserveState
      listenTo={Global.getGlobal()}
      control={() => {
        if (!Global.getJwtToken()) {
          return <HasAuth />;
        } else {
          return <RequireAuth />;
        }
      }}
    />
  );
}

export default App;
