/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductDataInventoryDetail } from './ProductDataInventoryDetail';
import {
    ProductDataInventoryDetailFromJSON,
    ProductDataInventoryDetailFromJSONTyped,
    ProductDataInventoryDetailToJSON,
} from './ProductDataInventoryDetail';
import type { ProductDataDateCodeDetailInner } from './ProductDataDateCodeDetailInner';
import {
    ProductDataDateCodeDetailInnerFromJSON,
    ProductDataDateCodeDetailInnerFromJSONTyped,
    ProductDataDateCodeDetailInnerToJSON,
} from './ProductDataDateCodeDetailInner';

/**
 * 
 * @export
 * @interface ProductData
 */
export interface ProductData {
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    productName?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductData
     */
    qtyRequested?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductData
     */
    targetPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductData
     */
    qtyOnHand?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    manufacturer?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductData
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    shortDateCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    dateCode?: string;
    /**
     * 
     * @type {Array<ProductDataDateCodeDetailInner>}
     * @memberof ProductData
     */
    dateCodeDetail?: Array<ProductDataDateCodeDetailInner>;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    certifications?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductData
     */
    doNotQuote?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    leadTime?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductData
     */
    lineTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductData
     */
    message?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductData
     */
    processingNotes?: Array<string>;
    /**
     * 
     * @type {Array<ProductDataInventoryDetail>}
     * @memberof ProductData
     */
    inventoryDetail?: Array<ProductDataInventoryDetail>;
    /**
     * 
     * @type {object}
     * @memberof ProductData
     */
    additionalData?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductData
     */
    cannotReplyReason?: Array<string>;
}

/**
 * Check if a given object implements the ProductData interface.
 */
export function instanceOfProductData(value: object): value is ProductData {
    return true;
}

export function ProductDataFromJSON(json: any): ProductData {
    return ProductDataFromJSONTyped(json, false);
}

export function ProductDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductData {
    if (json == null) {
        return json;
    }
    return {
        
        'productId': json['productId'] == null ? undefined : json['productId'],
        'productName': json['productName'] == null ? undefined : json['productName'],
        'qtyRequested': json['qtyRequested'] == null ? undefined : json['qtyRequested'],
        'targetPrice': json['targetPrice'] == null ? undefined : json['targetPrice'],
        'qtyOnHand': json['qtyOnHand'] == null ? undefined : json['qtyOnHand'],
        'manufacturer': json['manufacturer'] == null ? undefined : json['manufacturer'],
        'price': json['price'] == null ? undefined : json['price'],
        'shortDateCode': json['shortDateCode'] == null ? undefined : json['shortDateCode'],
        'dateCode': json['dateCode'] == null ? undefined : json['dateCode'],
        'dateCodeDetail': json['dateCodeDetail'] == null ? undefined : ((json['dateCodeDetail'] as Array<any>).map(ProductDataDateCodeDetailInnerFromJSON)),
        'certifications': json['certifications'] == null ? undefined : json['certifications'],
        'doNotQuote': json['doNotQuote'] == null ? undefined : json['doNotQuote'],
        'leadTime': json['leadTime'] == null ? undefined : json['leadTime'],
        'lineTotal': json['lineTotal'] == null ? undefined : json['lineTotal'],
        'message': json['message'] == null ? undefined : json['message'],
        'processingNotes': json['processingNotes'] == null ? undefined : json['processingNotes'],
        'inventoryDetail': json['inventoryDetail'] == null ? undefined : ((json['inventoryDetail'] as Array<any>).map(ProductDataInventoryDetailFromJSON)),
        'additionalData': json['additionalData'] == null ? undefined : json['additionalData'],
        'cannotReplyReason': json['cannotReplyReason'] == null ? undefined : json['cannotReplyReason'],
    };
}

export function ProductDataToJSON(value?: ProductData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'productId': value['productId'],
        'productName': value['productName'],
        'qtyRequested': value['qtyRequested'],
        'targetPrice': value['targetPrice'],
        'qtyOnHand': value['qtyOnHand'],
        'manufacturer': value['manufacturer'],
        'price': value['price'],
        'shortDateCode': value['shortDateCode'],
        'dateCode': value['dateCode'],
        'dateCodeDetail': value['dateCodeDetail'] == null ? undefined : ((value['dateCodeDetail'] as Array<any>).map(ProductDataDateCodeDetailInnerToJSON)),
        'certifications': value['certifications'],
        'doNotQuote': value['doNotQuote'],
        'leadTime': value['leadTime'],
        'lineTotal': value['lineTotal'],
        'message': value['message'],
        'processingNotes': value['processingNotes'],
        'inventoryDetail': value['inventoryDetail'] == null ? undefined : ((value['inventoryDetail'] as Array<any>).map(ProductDataInventoryDetailToJSON)),
        'additionalData': value['additionalData'],
        'cannotReplyReason': value['cannotReplyReason'],
    };
}

