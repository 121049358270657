import {
  Box,
  MenuItem,
} from "@mui/material";
import { User } from "../../services/models";
import { useEffect, useState } from "react";
import { Services } from "../../Services";
import { Global } from "../../Global";
import ALNModal from "../../components/modal/ALNModal";
import ALNModalBody from "../../components/modal/ALNModalBody";
import ALNModalHeader from "../../components/modal/ALNModalHeader";
import { ALNColors } from "../../utils/color";
import ALNInput from "../../components/form/ALNInput/ALNInput";
import ALNBtn from "../../components/form/ALNBtn/ALNBtn";
import ALNLabel from "../../components/label/ALNLabel";
import ALNModalTitle from "../../components/modal/ALNModalTitle";
import ALNCancelBtn from "../../components/form/ALNBtn/ALNCancelBtn";

type UpdateUserModalProps = {
  open: boolean;
  onClose: () => void;
  user: User;
  title: string;
  currentlyChanged: "password" | "company";
};

export function UpdateUserModal(props: UpdateUserModalProps) {
  const [password, setPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [company, setCompany] = useState<string>();
  const [companies, setCompanies] = useState<string[] | undefined>([]);
  const [currentPasswordError, setCurrentPasswordError] = useState<boolean>(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState<boolean>(false);
  const [newPasswordError, setNewPasswordError] = useState<boolean>(false);

  let token = Global.getJwtToken();

  useEffect(() => {
    setCompany(Global.getGlobal().company);
  }, [token]);

  useEffect(() => {
    Global.getAllCompanies().then((companies) => {
      setCompanies(companies);
    });
  }, [props.user]);

  const updateUserCompany = () => {
    if (props.user.emailAddress && props.user.id && company) {
      Services.userService
        .changeCompany(Global.getJwtToken()!, company)
        .then((t) => {
          Global.setJwtToken(t);
          props.onClose();
        });
    }
  };

  const updateUserPassword = () => {
    if (newPassword !== confirmPassword) {
      setConfirmPasswordError(true);
      return;
    }

    if(newPassword === "") {
      setNewPasswordError(true);
      return;
    }
    if (props.user.emailAddress && props.user.id) {
      Services.userService
        .changePassword(props.user.emailAddress, password, newPassword)
        .then(() => {
          props.onClose();
        })
        .catch(() => {
          setCurrentPasswordError(true);
        })
    }
  };

  const handleClose = () => {
    setCurrentPasswordError(false);
    setNewPasswordError(false);
    setConfirmPasswordError(false);
    props.onClose();
  };

  return (
    <ALNModal open={props.open} onClose={props.onClose}>
      <ALNModalHeader>
        <ALNModalTitle title = {props.currentlyChanged === "password" ? "Change Password" : "Change Company"} />
        <ALNCancelBtn onClick = { handleClose } />
      </ALNModalHeader>
      <ALNModalBody
        sx={{
          paddingTop: "20px",
          paddingLeft: "30px",
          paddingRight: "30px",
          paddingBottom: "20px",
          display: 'flex',
          flexDirection: 'column',
          height: props.currentlyChanged === "company" ? "200px" : "340px",
        }}
      >
        {props.currentlyChanged === "password" && (
          <Box>
            <ALNLabel variant="subtitle2" sx={{ marginTop: "0px" }}>
              Current Password
              {
                currentPasswordError && (
                  <ALNLabel sx={{ display: 'inline', paddingLeft: "18px", color: ALNColors.red, fontSize: "12px" }}>
                    Incorrect Password
                  </ALNLabel>
                )
              }
            </ALNLabel>
            <ALNInput 
              value={password}
              onChange={(e: any) => {
                setCurrentPasswordError(false);
                setPassword(e.target.value);
              }}
              label="Password"
              type="password"
            />
            <ALNLabel variant="subtitle2" sx={{ marginTop: "20px" }}>
              New Password
              {
                newPasswordError && (
                  <ALNLabel sx={{ display: 'inline', paddingLeft: "18px", color: ALNColors.red, fontSize: "12px" }}>
                    Required Password
                  </ALNLabel>
                )
              }
            </ALNLabel>
            <ALNInput 
              value={newPassword}
              onChange={(e: any) => {
                setNewPasswordError(false);
                setConfirmPasswordError(false);
                setNewPassword(e.target.value);
              }}
              label="New Password"
              type="password"
            />
            <ALNLabel variant="subtitle2" sx={{ marginTop: "20px" }}>
              Confirm Password
            {
              confirmPasswordError && (
                <ALNLabel sx={{ display: 'inline', paddingLeft: "18px", color: ALNColors.red, fontSize: "12px" }}>
                  Incorrect Password
                </ALNLabel>
              )
            }
            </ALNLabel>
            <ALNInput 
              value={confirmPassword}
              onChange={(e: any) => {
                setConfirmPasswordError(false);
                setConfirmPassword(e.target.value);
              }}
              label="Confirm Password"
              type="password"
            />
          </Box>
        )}

        {props.currentlyChanged === "company" && (
          <Box>
            <ALNLabel variant="subtitle2" sx={{ marginTop: "20px", marginBottom: "10px" }}>Edit Company</ALNLabel>
            <ALNInput 
              type="select"
              label="Company"
              required={true}
              id="company"
              name="company"
              value={company || ""}
              onChange={(e: any) => setCompany(e.target.value)}
            >
              {(companies ?? []).map((company) => (
                <MenuItem key={company} value={company}>
                  {company}
                </MenuItem>
              ))}
            </ALNInput>
          </Box>
        )}
        <ALNBtn 
            variant="contained"
            type="submit"
            sx={{ bgcolor: ALNColors.email_manual, width: "120px", mb: 2 ,ml: "auto", float: "inline-end", mt: props.currentlyChanged === "password" ? 4 : 6}}
            onClick={ () => {
              props.currentlyChanged === "password"
                ? updateUserPassword()
                : updateUserCompany()
            }}
          >
              Submit
          </ALNBtn>

      </ALNModalBody>
    </ALNModal>
  );
}
