import { Box } from "@mui/material";
import { EmailData } from "../services/models/EmailData";
import { ALNColors } from "../utils/color";
import ALNLabel from "../components/label/ALNLabel";

export function DisplayEmail(props: {
  email: EmailData;
  isReply: boolean;
  startCollapsed?: boolean;
}): JSX.Element {
  return (
    <Box 
      sx={{
        padding: '30px',
        paddingLeft: "45px",
        overflowY: "auto",
      }}
    >
      {(props.email.body || props.email.htmlBody) && (
        <Box>
          <Box sx={{display: 'flex', justifyContent: 'space-between', boder: "none"}}>
            <ALNLabel variant="h5" sx={{ color: ALNColors.black, border: "none", marginBottom: "8px", fontWeight: "600" }}>
              {props.isReply ? "Reply Email" : "Inbound Email"}
            </ALNLabel>
          </Box>
          <Box>
            <Box sx={{ mb: 2, display: "flex" }}>
              <ALNLabel
                variant="subtitle1"
              >
                From:
              </ALNLabel>
              <ALNLabel variant="subtitle1"
                sx={{
                  marginLeft: "5px",
                  fontWeight: "600"
                }}
              >
                {props.email?.from?.address}
              </ALNLabel>
            </Box>
            <Box sx={{ mb: 2, display: "flex" }}>
              <ALNLabel
                variant="subtitle1"
              >
                Date:
              </ALNLabel>
              <ALNLabel variant="subtitle1"
                sx={{
                  marginLeft: "5px",
                  fontWeight: "600"
                }}
              >
                {props.email?.date?.toLocaleString()}
              </ALNLabel>
            </Box>
            <Box sx={{ mb: 2, display: "flex" }}>
              <ALNLabel
                variant="subtitle1"
              >
                Subject:
              </ALNLabel>
              <ALNLabel variant="subtitle1"
                sx={{
                  marginLeft: "5px",
                  fontWeight: "600"
                }}
              >
                {props.email?.subject}
              </ALNLabel>
            </Box>
            {!props.email?.htmlBody && (
              <Box sx={{ mb: 2 }}>
                rendering as text
                <ALNLabel
                  variant="subtitle1"
                >
                  Body:
                </ALNLabel>
                <Box my={2} />
                <ALNLabel
                  variant="body1"
                  sx={{ 
                    marginLeft: "14px",
                    whiteSpace: "pre-wrap",
                    display: 'block', 
                    fontFamily: 'monospace', 
                  }}
                >
                  {props.email?.body}
                </ALNLabel>
              </Box>
            )}
            {props.email?.htmlBody && (
              <Box sx={{ mb: 2 }}>
                <ALNLabel
                  variant="subtitle1"
                >
                  Body:
                </ALNLabel>
                <Box
                  sx={{
                    marginLeft: "14px",
                  }}
                  dangerouslySetInnerHTML={{ __html: props.email.htmlBody }}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}
