import React from 'react';
import { TextField } from '@mui/material';
import { ALNColors } from '../../../utils/color';

interface ALNInputProps {
  fullwidth?: boolean;
  value: string | number | undefined;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  id?: string;
  name?: string;
  label?: string;
  required?: boolean;
  type?: string | undefined;
  placeholder?: string;
  children? : React.ReactNode;
  rows? :number | undefined
}

function ALNInput ({
  fullwidth = true,
  value,
  onChange,
  name,
  id,
  label,
  required = true,
  type,
  placeholder = "-",
  children,
  rows
}: ALNInputProps) {
  const inputStyles = {
    fontFamily: "'OpenSans', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: ALNColors.primary_btn,
    margin: 0,
    "&::before": { content: "none" },
    "&::after": { content: "none" },
    "&.Mui-focused::before": { content: "none" },
    "&.Mui-focused::after": { content: "none" },
    "& input": {
      padding: '16px',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      color: ALNColors.gray,
      height: 'fit-content',
      borderRadius: '8px',
      borderColor: ALNColors.primary_input_border,
      borderWidth: '1.2px',
      transition: 'border-color 0.3s ease',
      fontFamily: "'OpenSans', sans-serif"
    }
  };

  return (
    <TextField
      fullWidth={fullwidth}
      margin="normal"
      variant="filled"
      id={id}
      name={name}
      label={label}
      placeholder={placeholder}
      value={value || ""}
      onChange={onChange}
      required={required}
      type={type === "select" ? "": type}
      sx={inputStyles}
      select={type === "select" ? true : false}
      multiline={type ==="multiline" ? true : false}
      rows={rows}
    >
      {children}
    </TextField>
  );
};

export default ALNInput;
