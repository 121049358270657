import { useEffect, useState } from "react";
import logoImage from "../assets/images/logo_b2.png";
import { ReactComponent as FilterDownIcon } from "../assets/images/filter-down-icon.svg";
import { Services } from "../Services";
import { UpdateUserModal } from "../pages/user/UpdateUserModal";

import { MailBox } from "../services/models/MailBox";
import { Global, Pages } from "../Global";
import { ObserveState } from "../StateManagement/ObserveState";
import { Box, SvgIcon, Menu, MenuItem, Divider } from "@mui/material";
import { ALNColors } from "../utils/color";
import ALNLabel from "../components/label/ALNLabel";
import { ReactComponent as DashboardIcon } from "../assets/images/dashboard.svg";
import { ReactComponent as DashboardReasonIcon } from "../assets/images/dashboardreasons.svg";
import { ReactComponent as DashboardProductsIcon } from "../assets/images/dashboardproducts.svg";
import { ReactComponent as DashboardUserProductsIcon } from "../assets/images/dashboarduserproducts.svg";
import { ReactComponent as DashboardUsersIcon } from "../assets/images/dashboardusers.svg";
import { ReactComponent as InboxIcon } from "../assets/images/inbox.svg";
import { ReactComponent as UsersIcon } from "../assets/images/users.svg";
import { ReactComponent as CompaniesIcon } from "../assets/images/companies.svg";
import { ReactComponent as MailboxesIcon } from "../assets/images/mailboxes.svg";
import { ReactComponent as EmailAddressIcon } from "../assets/images/blockedEmails.svg";
import { ReactComponent as QuoteToolIcon } from "../assets/images/quoteTool.svg";
import { ReactComponent as ParseToolIcon } from "../assets/images/parseTool.svg";
import { ReactComponent as SupportIcon } from "../assets/images/support.svg";
import { ReactComponent as SettingsIcon } from "../assets/images/settings-icon.svg";
import { PortalInfo } from "../services/models/PortalInfo";
import { User } from "../services/models/User";
import avatarImg from "../assets/images/avatar.png";
import { ReactComponent as DownIcon } from "../assets/images/down-icon.svg";
import { ReactComponent as Logout } from "../assets/images/log-out.svg";

function Sidebar() {
  const [mailBoxes, setMailBoxes] = useState<MailBox[]>([]);
  const [arrowFlag, setArrowFlag] = useState(false);
  const [dashboardSubMenuName, setDashboardSubMenuName] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [updateUserModal, setUpdateUserModal] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [currentlyChanged, setCurrentlyChanged] = useState<
    "password" | "company"
  >("password");

  const [portalInfo, setPortalInfo] = useState<PortalInfo | undefined>(
    undefined,
  );
  // const title = Global.getGlobal().getBreadcrumbTitle();

  const handleUserInfo = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUpdateUserModal = () => {
    setUpdateUserModal(true);
  };

  const handleCloseUpdateUserModal = () => {
    setUpdateUserModal(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    Global.setJwtToken(undefined);
  };

  const mainMenus = [
    {
      key: Pages.Dashboard,
      icon: (
        <SvgIcon>
          <DashboardIcon />
        </SvgIcon>
      ),
      label: (
        <ALNLabel
          sx={{
            fontFamily: '"Open Sans", sans-serif',
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: 500,
            width: "fit-content",
          }}
        >
          Dashboard
        </ALNLabel>
      ),
      subMenus: [
        {
          key: Pages.DashboardReasons,
          icon: (
            <SvgIcon>
              <DashboardReasonIcon />
            </SvgIcon>
          ),
          label: (
            <ALNLabel
              sx={{
                fontSize: "14px",
                lineHeight: "21px",
                fontWeight: 500,
                fontFamily: '"Open Sans", sans-serif',
              }}
            >
              - Reasons
            </ALNLabel>
          ),
          dashboardName: Pages.DashboardReasons,
        },

        {
          key: Pages.DashboardProducts,
          icon: (
            <SvgIcon>
              <DashboardProductsIcon />
            </SvgIcon>
          ),
          label: (
            <ALNLabel
              sx={{
                fontSize: "14px",
                lineHeight: "21px",
                fontWeight: 500,
                fontFamily: '"Open Sans", sans-serif',
              }}
            >
              - Products
            </ALNLabel>
          ),
          dashboardName: "Products",
        },

        {
          key: Pages.DashboardCustomerProducts,
          icon: (
            <SvgIcon>
              <DashboardUserProductsIcon />
            </SvgIcon>
          ),
          label: (
            <ALNLabel
              sx={{
                fontSize: "14px",
                lineHeight: "21px",
                fontWeight: 500,
                fontFamily: '"Open Sans", sans-serif',
              }}
            >
              - Customer Products
            </ALNLabel>
          ),
          dashboardName: "UserProducts",
        },

        {
          key: Pages.DashboardCustomers,
          icon: (
            <SvgIcon>
              <DashboardUsersIcon />
            </SvgIcon>
          ),
          label: (
            <ALNLabel
              sx={{
                fontSize: "14px",
                lineHeight: "21px",
                fontWeight: 500,
                fontFamily: '"Open Sans", sans-serif',
              }}
            >
              - Customers
            </ALNLabel>
          ),
          dashboardName: "Users",
        },
      ],
    },

    {
      key: Pages.EmailList,
      icon: (
        <SvgIcon>
          <InboxIcon />
        </SvgIcon>
      ),
      label: (
        <ALNLabel
          sx={{
            fontFamily: '"Open Sans", sans-serif',
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: 500,
            width: "fit-content",
          }}
        >
          E-Mail
        </ALNLabel>
      ),
      subMenus:
        mailBoxes.length > 0
          ? mailBoxes.map((mailbox, index) => ({
              key: `mailbox-${index}`,
              mailboxName: mailbox.emailAddress,
              label: (
                <ALNLabel
                  sx={{
                    fontFamily: '"Open Sans", sans-serif',
                    fontSize: "14px",
                    lineHeight: "21px",
                    fontWeight: 500,
                    width: "fit-content",
                    paddingLeft: "0px",
                  }}
                >{`${"- " + mailbox.emailAddress?.substring(0, mailbox.emailAddress?.indexOf("@"))}`}</ALNLabel>
              ),
            }))
          : [],
    },
    {
      key: Pages.UserList,
      icon: (
        <SvgIcon>
          <UsersIcon />
        </SvgIcon>
      ),
      label: (
        <ALNLabel
          sx={{
            fontFamily: '"Open Sans", sans-serif',
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: 500,
            width: "fit-content",
          }}
        >
          User List
        </ALNLabel>
      ),
    },
    {
      key: Pages.Companies,
      icon: (
        <SvgIcon>
          <CompaniesIcon />
        </SvgIcon>
      ),
      label: (
        <ALNLabel
          sx={{
            fontFamily: '"Open Sans", sans-serif',
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: 500,
            width: "fit-content",
          }}
        >
          Companies
        </ALNLabel>
      ),
    },
    {
      key: Pages.MailBoxList,
      icon: (
        <SvgIcon>
          <MailboxesIcon />
        </SvgIcon>
      ),
      label: (
        <ALNLabel
          sx={{
            fontFamily: '"Open Sans", sans-serif',
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: 500,
            width: "fit-content",
          }}
        >
          Mailboxes
        </ALNLabel>
      ),
    },
    {
      key: Pages.EmailAddressRules,
      icon: (
        <SvgIcon>
          <EmailAddressIcon />
        </SvgIcon>
      ),
      label: (
        <ALNLabel
          sx={{
            fontFamily: '"Open Sans", sans-serif',
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: 500,
            width: "fit-content",
          }}
        >
          Email Rules
        </ALNLabel>
      ),
    },
    {
      key: Pages.QuoteTool,
      icon: (
        <SvgIcon>
          <QuoteToolIcon />
        </SvgIcon>
      ),
      label: (
        <ALNLabel
          sx={{
            fontFamily: '"Open Sans", sans-serif',
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: 500,
            width: "fit-content",
          }}
        >
          Quote Tool
        </ALNLabel>
      ),
    },
    {
      key: Pages.EmailParseTest,
      icon: (
        <SvgIcon>
          <ParseToolIcon />
        </SvgIcon>
      ),
      label: (
        <ALNLabel
          sx={{
            fontFamily: '"Open Sans", sans-serif',
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: 500,
            width: "fit-content",
          }}
        >
          Email Parse Test Tool
        </ALNLabel>
      ),
    },
    {
      key: Pages.Support,
      icon: (
        <SvgIcon>
          <SupportIcon />
        </SvgIcon>
      ),
      label: (
        <ALNLabel
          sx={{
            fontFamily: '"Open Sans", sans-serif',
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: 500,
            width: "fit-content",
          }}
        >
          Support
        </ALNLabel>
      ),
    },
    {
      key: Pages.Settings,
      icon: (
        <SvgIcon>
          <SettingsIcon />
        </SvgIcon>
      ),
      label: (
        <ALNLabel
          sx={{
            fontFamily: '"Open Sans", sans-serif',
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: 500,
            width: "fit-content",
          }}
        >
          Settings
        </ALNLabel>
      ),
    },
  ];

  let token = Global.getJwtToken();
  useEffect(() => {
    Global.getUser().then((user) => {
      setUser(user);
    });
    Services.mailBoxService
      .getMailBoxes(Global.getJwtToken()!)
      .then((mailBoxes: any) => {
        setMailBoxes(mailBoxes);
      })
      .catch((e: any) => {
        console.error(e);
      });
    Global.getPortalInfo().then((portalInfo) => {
      setPortalInfo(portalInfo);
    });
  }, [token]);

  const handleMenuItem = (menu: any) => {
    setDashboardSubMenuName("");
    if (menu.subMenus && menu.subMenus.length > 0) {
      if (Global.getGlobal().CurrentPage == menu.key) setArrowFlag(!arrowFlag);
      else setArrowFlag(true);
    } else {
      setArrowFlag(false);
    }
    Global.getGlobal().CurrentPage = menu.key;
  };

  const handleSubMenuItem = (subMenu: any) => {
    if (subMenu.key.startsWith("mailbox")) {
      setDashboardSubMenuName("");
      Global.getGlobal().CurrentMailbox = subMenu.mailboxName;
    } else {
      setDashboardSubMenuName(subMenu.dashboardName);
      Global.getGlobal().CurrentPage = subMenu.key;
    }
  };
  const [user, setUser] = useState<User | undefined>();

  let renderMenus = [...mainMenus];

  if (!user?.canAdministerCompanies) {
    renderMenus = renderMenus.filter((menu) => menu.key !== Pages.Companies);
  }

  if (!user?.canAdministerMailBoxes) {
    renderMenus = renderMenus.filter((menu) => menu.key !== Pages.MailBoxList);
  }

  if (!user?.canAdminsterEmailRules) {
    renderMenus = renderMenus.filter(
      (menu) => menu.key !== Pages.EmailAddressRules,
    );
  }

  if (!user?.canAdministerUsers) {
    renderMenus = renderMenus.filter((menu) => menu.key !== Pages.UserList);
  }

  return (
    <ObserveState
      listenTo={Global.getGlobal()}
      control={() => {
        return (
          <Box
            sx={{
              width: "220px",
              height: "calc(100vh - 50px)",
              paddingLeft: "35px",
              paddingRight: "8px",
              paddingTop: "25px",
              paddingBottom: "25px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              overflowY: "hidden",
              overflowX: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "50px",
              }}
            >
              <Box
                component="img"
                src={logoImage}
                alt="logo"
                sx={{
                  marginLeft: "60px",
                  width: "70px",
                  height: "70px",
                }}
              />
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={"4px"}
                alignItems={"start"}
                height={"100%"}
                marginTop={"-32px"}
              >
                {renderMenus.map((menu) => (
                  <Box
                    sx={{
                      "&:hover": {
                        ".MuiMenuItem-root": {
                          "& a": {
                            color: ALNColors.email_withissue,
                          },
                          "& p": {
                            color: ALNColors.email_withissue,
                          },
                        },
                        ".MuiSvgIcon-root": {
                          filter: "brightness(0.7) hue-rotate(150deg)",
                        },
                      },
                      marginTop: menu.key === "Support" ? "50px" : "0px",
                      width: "100%",
                    }}
                    key={menu.key}
                  >
                    <Box
                      sx={{
                        padding: "8px 16px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "calc(100% - 32px)",
                        borderRadius: "5px",
                        cursor: "pointer",
                        fontFamily: '"Open Sans", sans-serif',
                        backgroundColor:
                          Global.getGlobal().CurrentPage === menu.key
                            ? ALNColors.backgroud_transparent
                            : "transparent",
                        "&:hover": {
                          backgroundColor: ALNColors.backgroud_transparent,
                        },
                        "& .rotate": {
                          transform: "rotate(180deg)",
                        },
                        "& a, & p": {
                          fontFamily: '"Open Sans", sans-serif',
                          fontSize: "14px",
                          fontWeight: 530,
                          lineHeight: "21px",
                          letterSpacing: "0.02em",
                          textAlign: "left",
                          textDecoration: "none",
                          paddingLeft: "6px",
                          color: ALNColors.green_very_dark,
                          margin: 0,
                          width: "100%",
                          borderRadius: "5px",
                        },
                      }}
                      onClick={() => handleMenuItem(menu)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        {menu.icon}
                        {menu.label}
                      </Box>
                      {menu.subMenus &&
                        menu.subMenus.length > 0 &&
                        (Global.getGlobal().CurrentPage.split("/")[0] ==
                          menu.key && arrowFlag ? (
                          <FilterDownIcon
                            cursor={"pointer"}
                            style={{
                              transform: "rotate(180deg)",
                            }}
                          />
                        ) : (
                          <FilterDownIcon
                            style={{
                              transform: "rotate(0deg)",
                            }}
                            cursor={"pointer"}
                          />
                        ))}
                    </Box>
                    {menu.subMenus &&
                      menu.subMenus.length > 0 &&
                      Global.getGlobal().CurrentPage.split("/")[0] ==
                        menu.key &&
                      arrowFlag && (
                        <Box
                          sx={{
                            padding: "2px 20px 2px 20px",
                            maxHeight: "200px",
                          }}
                        >
                          {menu.subMenus.map((subMenu) => 
                            {
                              if ('mailboxName' in subMenu) {
                                return (<Box
                                  className={`sub-menu-item ${Global.getGlobal().CurrentMailbox === subMenu.mailboxName ? "sub-menu-item-active" : ""}`}
                                  key={subMenu.key}
                                  onClick={() => handleSubMenuItem(subMenu)}
                                  sx={{
                                    padding: "8px",
                                    marginTop: "4px",
                                    fontFamily: "OpenSans",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                    lineHeight: "21px",
                                    letterSpacing: "0.02em",
                                    borderRadius: "5px",
                                    display: "flex",
                                    cursor: "pointer",
                                    "&:hover": {
                                      background: ALNColors.backgroud_transparent,
                                    },
                                    "&.sub-menu-item-active": {
                                      background: ALNColors.backgroud_transparent,
                                    },
                                  }}
                                >
                                  {subMenu.label}
                                </Box>);
                              } else {
                                return (<Box
                                  className={`sub-menu-item ${dashboardSubMenuName === subMenu.dashboardName ? "sub-menu-item-active" : ""}`}
                                  key={subMenu.key}
                                  onClick={() => handleSubMenuItem(subMenu)}
                                  sx={{
                                    padding: "8px",
                                    marginTop: "4px",
                                    fontFamily: "OpenSans",
                                    fontSize: "14px",
                                    fontWeight: 700,
                                    lineHeight: "21px",
                                    letterSpacing: "0.02em",
                                    borderRadius: "5px",
                                    display: "flex",
                                    cursor: "pointer",
                                    "&:hover": {
                                      background: ALNColors.backgroud_transparent,
                                    },
                                    "&.sub-menu-item-active": {
                                      background: ALNColors.backgroud_transparent,
                                    },
                                  }}
                                >
                                  {subMenu.label}
                                </Box>);
                              }
                            }
                          )}
                        </Box>
                      )}
                  </Box>
                ))}
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  gap: "10px",
                  padding: "8px 51px 8px 16px",
                }}
                onClick={handleUserInfo}
              >
                <Box
                  component="img"
                  src={avatarImg}
                  alt="avatar"
                  sx={{
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                  }}
                />
                <Box sx={{ margin: "0 0px" }}>
                  <ALNLabel>{`${user?.firstName} ${user?.lastName}`}</ALNLabel>
                  <ALNLabel sx={{ display: "none" }}>
                    {"["}
                    {Global.getGlobal().company}
                    {"]"}
                  </ALNLabel>
                </Box>
                <DownIcon />
              </Box>
              <Box sx={{ marginRight: "35px" }}>
                <ALNLabel
                  sx={{
                    margin: "auto",
                    fontSize: "12px",
                    width: "fit-content",
                    paddingTop: "10px",
                  }}
                >
                  Version: {portalInfo?.version}
                </ALNLabel>
              </Box>
            </Box>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Divider />
              <MenuItem
                onClick={() => {
                  handleUpdateUserModal();
                  setModalTitle("Change Password");
                  setCurrentlyChanged("password");
                }}
              >
                <ALNLabel variant="body1">Change Password</ALNLabel>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleUpdateUserModal();
                  setModalTitle("Change Company");
                  setCurrentlyChanged("company");
                }}
              >
                <ALNLabel variant="body1">Change Company</ALNLabel>
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <Logout />
                <ALNLabel variant="body1">Log out</ALNLabel>
              </MenuItem>
            </Menu>
            {user && (
              <UpdateUserModal
                currentlyChanged={currentlyChanged}
                open={updateUserModal}
                onClose={handleCloseUpdateUserModal}
                user={user!}
                title={modalTitle}
              />
            )}
          </Box>
        );
      }}
    />
  );
}

export default Sidebar;
