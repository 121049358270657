/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TimePeriod } from './TimePeriod';
import {
    TimePeriodFromJSON,
    TimePeriodFromJSONTyped,
    TimePeriodToJSON,
} from './TimePeriod';
import type { EmailStatus } from './EmailStatus';
import {
    EmailStatusFromJSON,
    EmailStatusFromJSONTyped,
    EmailStatusToJSON,
} from './EmailStatus';
import type { ProductBreakdownSort } from './ProductBreakdownSort';
import {
    ProductBreakdownSortFromJSON,
    ProductBreakdownSortFromJSONTyped,
    ProductBreakdownSortToJSON,
} from './ProductBreakdownSort';

/**
 * 
 * @export
 * @interface DashboardQueryParms
 */
export interface DashboardQueryParms {
    /**
     * 
     * @type {TimePeriod}
     * @memberof DashboardQueryParms
     */
    timePeriod: TimePeriod;
    /**
     * 
     * @type {ProductBreakdownSort}
     * @memberof DashboardQueryParms
     */
    sortBy: ProductBreakdownSort;
    /**
     * 
     * @type {string}
     * @memberof DashboardQueryParms
     */
    reason?: string;
    /**
     * 
     * @type {Array<EmailStatus>}
     * @memberof DashboardQueryParms
     */
    status?: Array<EmailStatus>;
    /**
     * 
     * @type {string}
     * @memberof DashboardQueryParms
     */
    mailbox?: string;
}



/**
 * Check if a given object implements the DashboardQueryParms interface.
 */
export function instanceOfDashboardQueryParms(value: object): value is DashboardQueryParms {
    if (!('timePeriod' in value) || value['timePeriod'] === undefined) return false;
    if (!('sortBy' in value) || value['sortBy'] === undefined) return false;
    return true;
}

export function DashboardQueryParmsFromJSON(json: any): DashboardQueryParms {
    return DashboardQueryParmsFromJSONTyped(json, false);
}

export function DashboardQueryParmsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardQueryParms {
    if (json == null) {
        return json;
    }
    return {
        
        'timePeriod': TimePeriodFromJSON(json['timePeriod']),
        'sortBy': ProductBreakdownSortFromJSON(json['sortBy']),
        'reason': json['reason'] == null ? undefined : json['reason'],
        'status': json['status'] == null ? undefined : ((json['status'] as Array<any>).map(EmailStatusFromJSON)),
        'mailbox': json['mailbox'] == null ? undefined : json['mailbox'],
    };
}

export function DashboardQueryParmsToJSON(value?: DashboardQueryParms | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'timePeriod': TimePeriodToJSON(value['timePeriod']),
        'sortBy': ProductBreakdownSortToJSON(value['sortBy']),
        'reason': value['reason'],
        'status': value['status'] == null ? undefined : ((value['status'] as Array<any>).map(EmailStatusToJSON)),
        'mailbox': value['mailbox'],
    };
}

