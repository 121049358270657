/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetProductCount200ResponseInner
 */
export interface GetProductCount200ResponseInner {
    /**
     * 
     * @type {number}
     * @memberof GetProductCount200ResponseInner
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof GetProductCount200ResponseInner
     */
    mailbox: string;
}

/**
 * Check if a given object implements the GetProductCount200ResponseInner interface.
 */
export function instanceOfGetProductCount200ResponseInner(value: object): value is GetProductCount200ResponseInner {
    if (!('count' in value) || value['count'] === undefined) return false;
    if (!('mailbox' in value) || value['mailbox'] === undefined) return false;
    return true;
}

export function GetProductCount200ResponseInnerFromJSON(json: any): GetProductCount200ResponseInner {
    return GetProductCount200ResponseInnerFromJSONTyped(json, false);
}

export function GetProductCount200ResponseInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetProductCount200ResponseInner {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'],
        'mailbox': json['mailbox'],
    };
}

export function GetProductCount200ResponseInnerToJSON(value?: GetProductCount200ResponseInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
        'mailbox': value['mailbox'],
    };
}

