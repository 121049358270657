import { FC } from "react";
import { Company } from "../../services/models";
import { Box } from "@mui/material";
import ALNItem from "../../components/form/ALNItem/ALNItem";
import ALNIconBtn from "../../components/form/ALNBtn/ALNIconBtn";
import deleteIcon from "../../assets/images/material-symbols_delete.svg"
import editIcon from "../../assets/images/tabler_edit.svg"
import { ALNColors } from "../../utils/color";

type CompanyListItemProps = {   
  company: Company;
  openEditModal: (company: Company) => void;
  onDelete: (company: Company) => void;
}

export function CompanyListItem({ company, openEditModal, onDelete }: CompanyListItemProps) {
  return (
    <Box 
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 20px',
        borderRadius: '4px',
        backgroundColor: ALNColors.white,
        boxShadow: `0px 1px 1px ${ALNColors.black_opacity}`,
        cursor: 'pointer',
        marginBottom: '16px'
      }}
    >
      <Box>
        <ALNItem firstName = { company.companyName } lastName = "" />
      </Box>
      <Box>
        <ALNIconBtn obj = { company } onClick = {() => openEditModal(company)} src = { editIcon } alt = "Edit" />
        <ALNIconBtn obj = { company } onClick = {() => onDelete(company)} src = { deleteIcon } alt = "Delete" />
      </Box>
    </Box>
  );
};