import React from "react";
import { Box, ToggleButton, ToggleButtonGroup, List, ListItem } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { Global } from "../Global";
import { Services } from "../Services";
import { ALNColors } from "../utils/color";
import ALNBtn from "../components/form/ALNBtn/ALNBtn";
import ALNLabel from "../components/label/ALNLabel";

type LogFileNames = {
  [key: string]: string[];
};

export function Support() {
  const [view, setView] = useState("logs");

  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: string,
  ) => {
    setView(newView);
  };

  let supportService = Services.supportService;

  const [productCounts, setProductCounts] = useState<
    { mailbox: string; count: number }[]
  >([]);
  const [logFileNames, setLogFileNames] = useState<LogFileNames>({});
  const [logFile, setLogFile] = useState<string>("");
  const [isOpen, setIsOpen] = useState<string>("");
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const [currentLogFile, setCurrentLogFile] = useState<string>("");

  let token = Global.getJwtToken();

  const toggleCollapse = (index: any) => {
    if(isOpen === index)
        setIsOpen("")
    else setIsOpen(index);
  };

  const fetchData = async () => {
    try {
      const productCountsData = await supportService.getProductCount(
        Global.getJwtToken()!,
      );
      setProductCounts(productCountsData);

      const logFileNamesData = await supportService.getLogFileNames(
        Global.getJwtToken()!,
      );
      
      type LogGroups = { [date: string]: string[] };
      const logsByDate: LogGroups = logFileNamesData.reduce((acc: LogGroups, log: string) => {
        const match = log.match(/2024-\d{2}-\d{2}/);
        if (match) {
          const date = match[0]; // Match result is guaranteed to exist here
          if (!acc[date]) acc[date] = [];
          acc[date].push(log);
        }
        return acc;
      }, {});
      
      // Sort logs within each date
      for (const date in logsByDate) {
        logsByDate[date].sort((a, b) => {
          const numA = parseInt(a.match(/-(\d+)\.log$/)?.[1] || "0", 10);
          const numB = parseInt(b.match(/-(\d+)\.log$/)?.[1] || "0", 10);
          return numA - numB;
        });
      }
      setLogFileNames(logsByDate);

      // const errorMessagesData = await supportService.getErrorMessages(
      //   Global.getJwtToken()!,
      // );

      await supportService.getErrorMessages(
        Global.getJwtToken()!,
      );
      
      setErrorMessages(errorMessages);

      if (currentLogFile) {
        const logFileData = await supportService.getLogFile(
          Global.getJwtToken()!,
          currentLogFile,
        );
        setLogFile(logFileData);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  useEffect(() => {
    const fetchLogFile = async () => {
      try {
        const logFileData = await supportService.getLogFile(
          Global.getJwtToken()!,
          currentLogFile,
        );
        setLogFile(logFileData);
      } catch (error) {
        console.error("Error fetching log file", error);
      }
    };

    if (currentLogFile) {
      fetchLogFile();
    }
  }, [currentLogFile]);

  return (
    <Box
      sx={{ 
        display: "flex", 
        flexDirection: "column",
        padding: "25px 50px",
        background: "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)"
      }}
    >
      <ALNLabel sx={{fontSize: "10px", lineHeight: "12px", fontWeight: 500, width: "fit-content"}}>
        Support
      </ALNLabel>
      <ALNLabel sx={{ marginTop: "20px", marginBottom: "20px", fontSize: "40px", lineHeight: "50px", fontWeight: 400, width: "fit-content",}}>
        Support
      </ALNLabel>
      <Box
        sx={{
          background: ALNColors.white,
          padding: "25px 25px", 
          borderRadius : "16px",
          border: "1px solid "+ALNColors.background_gray,
          height: 'calc(100vh - 210px)',
          overflowY: "hidden",
        }}
      >
        <Box sx={{overflow: "hidden"}} display="flex" justifyContent="space-between" alignItems="center">
          <ToggleButtonGroup
            value={view}
            exclusive
            onChange={handleViewChange}
            aria-label="view selection"
          >
            <ToggleButton value="logs" aria-label="logs">
              Logs
            </ToggleButton>
            <ToggleButton value="errors" aria-label="errors">
              Errors
            </ToggleButton>
            <ToggleButton value="productCounts" aria-label="product counts">
              Product Counts
            </ToggleButton>
          </ToggleButtonGroup>
          <ALNBtn variant="contained" onClick = { fetchData } 
             sx={{ bgcolor: ALNColors.email_manual, height: 45 }}
          >
            <ALNLabel
              sx={{
                fontSize: '16px !important',
                fontWeight: 400,
                fontFamily: "'Open Sans', sans-serif",
                lineHeight: 1.5,
                color: ALNColors.white,
                letterSpacing: '2%',
                textTransform: 'capitalize',
              }}
            >
              Refresh
            </ALNLabel>
          </ALNBtn>
        </Box>

        {view === "logs" && (
          <Box
            sx={{
              maxHeight: "85%",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <ALNLabel variant="h6" sx={{ paddingTop: "10px"}}>
              Log Files
            </ALNLabel>
            <Box display={"flex"} flexDirection={"row"} width={"100%"}>
              <ul>
                {Object.keys(logFileNames)
                  .sort()
                  .map((date) => (
                    <li key={date}>
                      <ALNLabel sx={{ fontWeight: 'bold', cursor: "pointer", width: "120px" }} onClick={() => { toggleCollapse(date); }}>
                        {date}
                      </ALNLabel>
                      {isOpen === date && 
                      <List>
                        {logFileNames[date]?.map((log) => (
                          <ListItem key={log} sx={{ padding: "0px"}}>
                            <ALNBtn
                              onClick={() => setCurrentLogFile(log)}
                              sx={{
                                color: currentLogFile === log ? "blue" : "inherit",
                              }}
                            >
                              <ALNLabel
                                sx={{
                                  fontFamily: "OpenSans",
                                  fontSize: "12px",
                                  fontWeight: 600,
                                  letterSpacing: 0.02,
                                  textAlign: "left",
                                  textTransform: "lowercase",
                                }}
                              >
                                {log}
                              </ALNLabel>
                            </ALNBtn>
                          </ListItem>
                        ))}
                      </List>
                      }
                    </li>
                  ))}
              </ul>
              <Box width={"10px"}></Box>
              <Box
                component="pre"
                sx={{
                  maxHeight: "75vh",
                  // overflowY: "auto",
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                }}
              >
                {logFile}
              </Box>
            </Box>
          </Box>
        )}

        {view === "errors" && (
          <Box
            sx={{
              maxHeight: "85%",
              overflowY: "hidden",
              overflowX: "hidden",
            }}
          >
            <ALNLabel variant="h6" sx={{ paddingTop: "10px"}}>
              Error Messages
            </ALNLabel>
            <ul>
              {errorMessages.map((error) => (
                <li key={error}>{error}</li>
              ))}
              {errorMessages.length === 0 && <li>No errors</li>}
            </ul>
          </Box>
        )}

        {view === "productCounts" && (
          <Box 
            sx={{
              maxHeight: "85%",
              overflowY: "hidden",
              overflowX: "hidden",
            }}
            display="flex" flexDirection="column">
            {productCounts.map((productCount, index) => (
              <Box
                key={productCount.mailbox}
                display="flex"
                justifyContent="space-between"
                width="100%"
                bgcolor={index % 2 === 0 ? ALNColors.white_medium_gray : ALNColors.white}
                sx={{ marginTop: "15px" }}
              >
                <ALNLabel variant="body1" sx={{ display: 'inline' }}>
                  {productCount.mailbox}
                </ALNLabel>
                <ALNLabel variant="body1" sx={{ display: 'inline' }}>
                  {productCount.count}
                </ALNLabel>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}
