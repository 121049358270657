import { useEffect, useState } from "react";
import { Box, Checkbox, Divider } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ALNBtn from "../components/form/ALNBtn/ALNBtn";

import { ALNColors } from "../utils/color";
import { User } from "../services/models";
import { Services } from "../Services";
import { UserListItem } from "./user/UserListItem";
import { NewUserModal } from "./user/NewUserModal";
import { ConfirmModal } from "./user/ConfirmModal";
import { Global } from "../Global";
import ALNLabel from "../components/label/ALNLabel";

export function UsersList(): JSX.Element {
  const [userList, setUserList] = useState<User[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [userModalOpen, setUserModalOpen] = useState<boolean>(false);
  const [deleteUser, setDeleteUser] = useState<User>();
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [editUser, setEditUser] = useState<User>({});
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const fetchUsers = () => {
    Services.userService
      .getUsers(Global.getJwtToken()!, false)
      .then((users: User[]) => {
        setUserList(users);
      })
      .catch((err) => {
        console.log(err)
      })
  };

  const token = Global.getJwtToken();

  useEffect(() => {
    fetchUsers();
  }, [token]);

  const handleSelectUser = (user: User) => {
    setSelectedUsers(prev => 
      prev.includes(user) ? prev.filter(u => u.id !== user.id) : [...prev, user]
    );
  };

  const handleDeleteSelectedUsers = () => {
    if (selectedUsers.length > 0) {
      setConfirmModalOpen(true);
    }
  }

  const selectAll = () => {
    if (!checkedAll) {
      setSelectedUsers([...userList]);
    } else {
      setSelectedUsers([]);
    }
    setCheckedAll(!checkedAll);
  }

  const handleUserClick = () => {
    setIsEdit(false);
    setUserModalOpen(true);
  };

  const handleUserModalClose = () => {
    setUserModalOpen(false);
    setEditUser({});
    setTimeout(() => {
      fetchUsers();
    }, 200);
    Global.resetUser();
  };

  const handleEditUser = (user: User) => {
    setEditUser((prevUser) => ({ ...prevUser, ...user }));
    setIsEdit(true);
    setUserModalOpen(true);
  };

  const handleConfirmodalClose = () => {
    setConfirmModalOpen(false);
  };

  const confirmDeleteUser = (user: User) => {
    setConfirmModalOpen(true);
    setDeleteUser(user);
  };

  const onDeleteUser = () => {
    if(!checkedAll && deleteUser) {
      Services.userService
        .deleteUser(Global.getJwtToken()!, deleteUser?.emailAddress!)
        .then(() => {
          fetchUsers();
        });
    } else {
      if (selectedUsers.length > 0) {
        for (let i = 0; i < selectedUsers.length; i ++) {
          Services.userService
          .deleteUser(Global.getJwtToken()!, selectedUsers[i].emailAddress!)
          .then(() => {
              if (i === selectedUsers.length-1) {
                fetchUsers();
              }
          });
        }
        setSelectedUsers([]);
      }
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        overflowY: "hidden",
        gap: "20px",
        padding: "25px 50px",
        background: "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)"
      }}
    >
      <ALNLabel sx={{fontSize: "10px", lineHeight: "12px", fontWeight: 500, width: "fit-content"}}>
        User List
      </ALNLabel>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '40px',
          marginBottom: '20px',
        }}
      >
        <ALNLabel sx={{fontSize: "40px", lineHeight: "50px", fontWeight: 400, width: "fit-content"}}>
          User List
        </ALNLabel>
        <ALNBtn 
          variant="contained"
          sx={{ bgcolor: ALNColors.email_manual, height: 45 }}
          onClick={handleUserClick}
          >
          <ALNLabel
            sx={{
              fontSize: '16px !important',
              fontWeight: 600,
              fontFamily: "'Open Sans', sans-serif",
              lineHeight: 1.5,
              color: ALNColors.white,
              letterSpacing: '2%',
              textTransform: 'capitalize',
            }}
          >
            New User
          </ALNLabel>
        </ALNBtn>
      </Box>
      <Box
        sx={{
          background: ALNColors.white,
          padding: "16px", 
          borderRadius : "16px",
          border: "1px solid "+ALNColors.background_gray,
          flexGrow: 1,
          height: 'calc(100vh - 220px)',
        }}
      >
        <Box
          sx={{
            maxHeight: '100%',
            overflowY: 'scroll'
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "15px",
            }}
          >
            <Checkbox 
              checked={checkedAll} 
              onChange={selectAll}
            />
            <DeleteForeverIcon
              sx={{
                cursor: "pointer"
              }}
              onClick={() => {
                handleDeleteSelectedUsers();
              }}
            />
          </Box>
          <Divider></Divider>
          
          {userList.map((user: User) => (
            <UserListItem
              user={user}
              key={user.id}
              onDelete={confirmDeleteUser}
              openEditModal={handleEditUser}
              onSelect={() => handleSelectUser(user)}
              selectedUsers={selectedUsers}
            />
          ))}
        </Box>
      </Box>
      <ConfirmModal
        open={confirmModalOpen}
        onClose={handleConfirmodalClose}
        onDeleteUser={onDeleteUser}
      />
      <NewUserModal
        isEdit={isEdit}
        open={userModalOpen}
        onClose={handleUserModalClose}
        user={editUser}
      />
    </Box>
  );
}
