import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Services } from "../Services";
import { Global } from "../Global";

import MailIcon from "@mui/icons-material/Mail";
import { TimePeriod } from "../services/models/TimePeriod";
import { MailBox } from "../services/models/MailBox";
import { ALNColors } from "../utils/color";
import { NotProcessedReason } from "../services/index";
import ALNLabel from "../components/label/ALNLabel";

function Dashboard_Reasons() {
  const [dashboardData, setDashboardData] = useState<
    NotProcessedReason[] | undefined
  >();
  const [timePeriod, setTimePeriod] = useState<TimePeriod>(TimePeriod.Day);
  const [mailbox, setMailbox] = useState<string>(
    Global.getGlobal().CurrentMailbox ?? "",
  );
  const [allMailboxes, setAllMailboxes] = useState<string[]>([]);

  const token = Global.getJwtToken();
  useEffect(() => {
    Services.mailBoxService
      .getMailBoxes(Global.getJwtToken()!)
      .then((mailboxes: MailBox[]) => {
        setAllMailboxes(mailboxes.map((mailbox) => mailbox.emailAddress!));
      });
  }, [token]);

  useEffect(() => {
    Services.dashboardService
      .getNonProcessedReasons(Global.getJwtToken()!, timePeriod, mailbox)
      .then((data) => {
        setDashboardData(data);
      });
  }, [timePeriod, mailbox]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "25px 50px",
        background:
          "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)",
      }}
    >
      <ALNLabel
        sx={{
          fontSize: "10px",
          lineHeight: "12px",
          fontWeight: 500,
          width: "fit-content",
        }}
      >
        Dashboard &gt; DashboardReasons
      </ALNLabel>
      <ALNLabel
        sx={{
          fontSize: "40px",
          lineHeight: "50px",
          fontWeight: 400,
          width: "fit-content",
        }}
      >
        DashboardReasons
      </ALNLabel>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"start"}
        sx={{
          background: ALNColors.white,
          padding: "16px",
          borderRadius: "16px",
          border: "1px solid " + ALNColors.background_gray,
          flexGrow: 1,
          height: 'calc(100vh - 200px)',
        }}>
        <Box display="flex" alignItems="center" justifyContent="space-between"
          sx={{
            paddingBottom: "10px",
          }}
        >
          <Box sx={{ minWidth: 200, display: "flex", alignItems: "center" }}>
            <MailIcon
              sx={{
                marginRight: 1,
                marginLeft: 5,
                color: ALNColors.indigo,
                alignItems: "baseline",
              }}
            />
            <FormControl
              variant="standard"
              sx={{ minWidth: 300, color: ALNColors.indigo }}
            >
              <InputLabel id="mailbox-select-label">Mailbox</InputLabel>
              <Select
                labelId="mailbox-select-label"
                id="mailbox-select"
                value={mailbox}
                label="Mailbox"
                onChange={(event) => {
                  Global.getGlobal().CurrentMailbox = event.target.value;
                  setMailbox(event.target.value);
                }}
              >
                {allMailboxes.map((mailbox) => (
                  <MenuItem key={mailbox} value={mailbox}>
                    {mailbox}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <ToggleButtonGroup
            value={timePeriod}
            exclusive
            onChange={(event, newTimePeriod) => {
              if (newTimePeriod !== null) {
                setTimePeriod(newTimePeriod);
              }
            }}
            aria-label="time period"
            sx={{
              minWidth: 500,
              justifyContent: "stretch",
              color: ALNColors.primary,
            }}
          >
            {Object.values(TimePeriod).map((period) => (
              <ToggleButton
                key={period}
                value={period}
                aria-label={period}
                sx={{
                  minWidth: 150,
                  border: "none",
                  "&.Mui-selected": {
                    backgroundColor: ALNColors.indigo,
                    color: ALNColors.white,
                    "&:hover": {
                      backgroundColor: ALNColors.white_blue,
                    },
                  },
                }}
              >
                {period}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>

        <Divider></Divider>

        {dashboardData && (
          <Box mt={2}>
            <Box
              display="flex"
              justifyContent="space-between"
              p={1}
              bgcolor={ALNColors.gray_very_dark}
              color={ALNColors.white}
            >
              <Box width="50%">User</Box>
              <Box width="50%">Count</Box>
            </Box>
            {dashboardData.map((item, index) => (
              <Box
                key={index}
                display="flex"
                justifyContent="space-between"
                p={1}
                bgcolor={index % 2 === 0 ? ALNColors.gray : ALNColors.white}
              >
                <Box width="50%">{item.reason}</Box>
                <Box width="50%">{item.count}</Box>
              </Box>
            ))}
            <Divider></Divider>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Dashboard_Reasons;
