import React from 'react';
import { Typography } from '@mui/material';

interface ALNLabelProps {
  children: React.ReactNode;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'caption' | 'overline' | undefined;
  sx?: object | undefined;
  onClick?: () => void | undefined;
}

function ALNLabel ({ 
  children,
  variant,
  sx,
  onClick,
}: ALNLabelProps) {
  return (
    <Typography
      sx={sx}
      variant={variant}
      onClick={onClick}
    >
      {children}
    </Typography>
  );
};

export default ALNLabel;
