import {
    Box,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { Global } from "../../Global";
  import { EmailParseRequest } from "../../services/models/EmailParseRequest";
  import { Services } from "../../Services";
  import { Email } from "../../services/models/Email";
  import { DisplayEmail } from "../DisplayEmail";
  import { EmailParseResult } from "../../services/models/EmailParseResult";
  import { EmailData } from "../../services/models/EmailData";
  import ReactQuill from "react-quill";
  import { ALNColors } from "../../utils/color";
  import ALNLabel from "../../components/label/ALNLabel";
  import ALNTextField from "../../components/form/ALNInput/ALNTextField";
  import ALNBtn from "../../components/form/ALNBtn/ALNBtn";
  import ALNToggleBtn from "../../components/form/ALNBtn/ALNToggleBtn";
  
  export function DialogEmailParseTool(props: { emailData?: EmailData }): JSX.Element {
    const [active, setActive] = useState(false);
    const [from, setFrom] = useState("");
    const [subject, setSubject] = useState("");
    const [body, setBody] = useState("");
    const [htmlBody, setHtmlBody] = useState<string | undefined>(undefined);
    const [result, resultsToShow] = useState("");
    const [resultsPage, setResultsPage] = useState<string>("email");
    const [resultData, setResultData] = useState<Email | undefined>();
    const [emailParseResults, setEmailParseResult] = useState<
      EmailParseResult | undefined
    >();
    const [productName, setProductName] = useState<string[] | undefined>();
  
    useEffect(() => {
      setFrom(props.emailData?.from?.address || "");
      setSubject(props.emailData?.subject || "");
      setBody(props.emailData?.body || "");
      setHtmlBody(props.emailData?.htmlBody);
    }, [props.emailData]);
  
    const handleTest = () => {
      let parseRequest: EmailParseRequest = {
        mailboxName: Global.getGlobal().CurrentMailbox!,
        email: {
          subject: subject,
          body: body,
          htmlBody: htmlBody,
          from: { address: from },
        },
      };
      Services.testService
        .parseEmail(Global.getJwtToken()!, parseRequest)
        .then((res) => {
          resultsToShow(JSON.stringify(res, null, 2));
          setResultData(res.email);
          setEmailParseResult(res);
        });
    };
  
    function handleProductNameFetch(): void {
      Services.testService
        .getRandomProductNames(
          Global.getJwtToken()!,
          Global.getGlobal().CurrentMailbox!,
        )
        .then((res) => {
          setProductName(res);
        });
    }
  
    let replyEmail = resultData?.reply;
  
    return (
    <Box
        sx={{
        display: "flex",
        flexDirection: "row",
        background: ALNColors.white,
        padding: "25px 25px", 
        borderRadius : "16px",
        border: "1px solid "+ALNColors.background_gray,
        height: 'calc(100vh - 320px)',
        }}
    >
        <Box
        sx={{
            width: "50%",
            paddingRight: "10px",
            borderRight: "1px solid "+ALNColors.black,
        }}
        >
        <Box sx={{ mb: 2 }}>
            <ALNTextField
            label="From"
            variant="filled"
            sx={{ width: "100%" }}
            value={from}
            onChange={setFrom}
            />
        </Box>
        <Box sx={{ mb: 2 }}>
            <ALNTextField
            label="Subject"
            variant="filled"
            sx={{ width: "100%" }}
            value={subject}
            onChange={setSubject}
            />
        </Box>
        {!htmlBody && (
            <Box sx={{ mb: 2 }}>
            <ALNTextField
                label="Body"
                variant="filled"
                sx={{ width: "100%", border: "none", height: "100%" }}
                value={body}
                rows={5}
                onChange={setBody}
            />
            </Box>
        )}

        {htmlBody && (
            <Box sx={{ mb: 6 }}>
            <ReactQuill
                theme="snow"
                value={htmlBody}
                onChange={setHtmlBody}
                style={{ height: "200px" }}
            />
            </Box>
        )}
        <ALNBtn variant="contained" onClick = { handleTest } >Test</ALNBtn>
        <ALNBtn onClick = { handleProductNameFetch } >Fetch Random Product Names</ALNBtn>
        {productName && (
            <Box>
            <ALNLabel variant="h5" sx={{ mb: '0.35em' }}>
                Random Product Names:
            </ALNLabel>
            <Box
                component="pre"
                sx={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
            >
                {productName.join(", ")}
            </Box>
            </Box>
        )}
        </Box>
        <Box sx={{ width: "50%", paddingLeft: "10px" }}>
        <ALNLabel variant="h5" sx={{ mb: '0.35em' }}>
            Results:
        </ALNLabel>

        <ALNToggleBtn
            active={active}
            firstToggle="email"
            secondToggle="data"
            aria_label="result type"
            aria_label_first="email"
            aria_label_second="data"
            sx={{ marginBottom: 2 }}
            onChange={ (value) => {
            setResultsPage(value); 
            setActive(!active);
            }}
        />
        {resultsPage === "email" &&
            (replyEmail?.body || replyEmail?.htmlBody) && (
            <Box 
                sx={{ 
                maxHeight: "77%",
                overflowY: "auto",
                overflowX: "hidden",
                }}
            >
                {emailParseResults && (
                <Box>
                    <ALNLabel>
                    Time Taken:{" "}
                    {emailParseResults.email?.timeTakenInSeconds?.toFixed(3)}{" "}
                    seconds
                    </ALNLabel>
                    <ALNLabel>
                    Order Total: ${" "}
                    {emailParseResults.email?.totalValue?.toFixed(2)}
                    </ALNLabel>
                    <ALNLabel>
                    Action Proposed: {emailParseResults.email?.emailStatus}
                    </ALNLabel>
                    {emailParseResults.email?.cannotReplyReason &&
                    emailParseResults.email?.cannotReplyReason?.length > 0 && (
                        <Box>
                        <ALNLabel>Issues:</ALNLabel>

                        <ALNLabel
                            sx={{
                            color: ALNColors.red,
                            border: "1px solid "+ALNColors.red,
                            backgroundColor: ALNColors.pink_light,
                            textAlign: "left",
                            }}
                        >
                            {emailParseResults.email?.cannotReplyReason?.map(
                            (reason) => (
                                <Box key={reason} component="span" display="block">
                                <ALNLabel variant="body2">- {reason}</ALNLabel>
                                </Box>
                            ),
                            )}
                        </ALNLabel>
                        </Box>
                    )}
                </Box>
                )}

                <Box sx={{ mb: 2 }}></Box>
                <DisplayEmail email={replyEmail!} isReply={true} />
            </Box>
            )}

        {resultsPage === "data" && (
            <Box
            component="pre"
            sx={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                maxHeight: "75%",
                overflowY: "auto",
                overflowX: "hidden",
            }}
            >
            {result}
            </Box>
        )}
        </Box>
    </Box>
    );
  }
  
  export default DialogEmailParseTool;
  