import { ALNColors } from "../../utils/color";
import ALNLabel from "../label/ALNLabel";
interface ALNModalTitleProps {
  title?: string;
}

function ALNModalTitle ({ title }: ALNModalTitleProps) {
  return (
    <ALNLabel
      sx={{
        fontFamily: "'OpenSans', sans-serif ",
        fontSize: '24px',
        fontWeight: 550,
        lineHeight: '1.5px',
        color: ALNColors.white,
        letterSpacing: 0.02,
        margin: 0,
      }}
    >
        { title }
    </ALNLabel>
  );
} 

export default ALNModalTitle;