import { Box } from "@mui/material";
import { useEffect } from "react";
import ALNCancelBtn from "../../components/form/ALNBtn/ALNCancelBtn";
import ALNModalTitle from "../../components/modal/ALNModalTitle";
import ALNModal from "../../components/modal/ALNModal";
import ALNModalHeader from "../../components/modal/ALNModalHeader";

import ALNBtn from "../../components/form/ALNBtn/ALNBtn";
import { ALNColors } from "../../utils/color";
interface ConfirmModalProps {
  open: boolean;
  onClose: () => void;
  onDeleteUser: () => void;
}

export function ConfirmModal(props: ConfirmModalProps): JSX.Element {
  useEffect(() => {
  }, []);

  const onAgree = () => {
    props.onDeleteUser();
    handleClose();
  };

  const onDisAgree = () => {
    handleClose();
  };
  
  const handleClose = () => {
    props.onClose();
  };

  return (
    <ALNModal open={props.open} onClose={props.onClose}>
      <ALNModalHeader>
        <ALNModalTitle title="Do you want to delete?" />
        <ALNCancelBtn onClick={handleClose} />
      </ALNModalHeader>
      <Box
        sx={{
          paddingLeft: "32px",
          paddingRight: "32px",
          display: 'flex',
          flexDirection: 'column',
          margin: "0px",
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: "center",
            height: '98px',
          }}
        >
            <ALNBtn 
                sx={{ 
                    borderRadius: '8px',
                    padding: '8px 16px',
                    marginRight: "28px",
                    bgcolor: ALNColors.primary_btn,
                    fontFamily: "OpenSans",
                    fontSize: '15px',
                    fontWeight: "fontWeightMedium",
                    lineHeight: '24px',
                    letterSpacing: 0.02,
                    textAlign: "left",
                    color: ALNColors.white,
                    cursor: "pointer",
                    width: "120px",
                    mt: 3,
                    mb: 3
                 }}
                onClick = {() => { onDisAgree() } }>
                    DISAGREE
            </ALNBtn>
            <ALNBtn 
                variant="contained"
                sx={{ 
                    borderRadius: '8px',
                    padding: '8px 16px',
                    marginRight: "28px",
                    bgcolor: ALNColors.primary_btn,
                    fontFamily: "OpenSans",
                    fontSize: '15px',
                    fontWeight: "fontWeightMedium",
                    lineHeight: '24px',
                    letterSpacing: 0.02,
                    textAlign: "left",
                    color: ALNColors.white,
                    cursor: "pointer",
                    width: "120px",
                    ml: 2,
                    mt: 3,
                    mb: 3
                }}
                onClick = {() => { onAgree() } }>
                    AGREE
            </ALNBtn>
        </Box>     
      </Box>
    </ALNModal>
  );
}
