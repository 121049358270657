import React, { useState } from "react";
import { ReactComponent as Logo } from "../assets/images/Logo.svg";
import { ReactComponent as UserIcon } from "../assets/images/user-icon.svg";
import { ReactComponent as PasswordIcon } from "../assets/images/password-icon.svg";
import AuthLeft from "./AuthLeft";
import { Services } from "../Services";
import { Global } from "../Global";
import { Box, Checkbox } from "@mui/material";
import { ALNColors } from "../utils/color";
import ALNInput from "../components/form/ALNInput/ALNInput";
import ALNBtn from "../components/form/ALNBtn/ALNBtn";
import ALNLabel from "../components/label/ALNLabel";

function LoginPage() {
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setPassword(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let userService = Services.userService;

    userService
      .login(username, password)
      .then((token: string) => {
        Global.setJwtToken(token);
      })
      .catch((e: any) => {
        console.error(e);
        setError("Invalid username or password " + e.message);
        Global.setJwtToken(undefined);
      });
  };

  return (
    <Box 
      sx={{  
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        overflowy: 'hidden'
      }}
    >
      <Box 
        sx={{
          width: '50%',
          bgcolor: ALNColors.primary_btn,
          textAlign: 'center',
          alignItems: 'center', 
          height: '100vh',
        }}>
        <AuthLeft
          title="Welcome!"
          description="Log in to unlock AI-powered and personalization of E-Mails."
        />
      </Box>
      <Box 
        sx={{  
          width: '50%',
          textAlign:' center'
        }}
      >
        <Box>
          <Logo/>
          <ALNLabel 
            sx={{
              fontFamily: "OpenSans",
              fontSize: '32px',
              fontWeight: 700,
              lineHeight: '48px',
              letterSpacing: 0.02,
              textAlign: 'center',
              color: ALNColors.gray_very_dark
            }}
          >Log in</ALNLabel>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{  marginLeft: '50%',
              transform:' translateX(-50%)'
            }}
          >
            <Box 
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '52px',
                marginTop: '2px',
              }}
            >
              <UserIcon
                style={{
                  position: 'absolute',
                  left: '10px',
                  pointerEvents: 'none'
                }}
              />
              <ALNInput 
                type="text"
                placeholder="Type Your User ID Here"
                id="username"
                name="username"
                value={username}
                onChange={handleUsernameChange}
              />
            </Box>
            <Box 
              sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '52px',
                marginTop: '2px',
              }}
            >
              <PasswordIcon 
                style={{
                  position: 'absolute',
                  left: '10px',
                  pointerEvents: 'none'
                }}
              />
              <ALNInput
                type="password"
                placeholder="Type Your Password Here"
                id="password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
              />
            </Box>
            {error && <ALNLabel>{error}</ALNLabel>}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '10px'
              }}
            >
              <Box 
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                <Checkbox id="remember" name="remember" />
                <ALNLabel
                  sx={{
                    fontFamily: '"OpenSans"',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '21px',
                    letterSpacing: 0.02,
                    textAlign: 'left',
                    color: ALNColors.gray
                  }}
                >Keep me logged in</ALNLabel>
              </Box>
            </Box>
            <ALNBtn
              type="submit"
              sx={{
                bgcolor: ALNColors.primary_btn,
                height: '52px',
                borderRadius: '8px',
                border: 'none',
                width: '100%',
                marginTop: '20px',
                fontFamily: '"OpenSans"',
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '27px',
                letterSpacing: '0.02em',
                textAlign: 'center',
                color: ALNColors.white
              }}
              onClick = { () => {
                
              } }>
                Log in
            </ALNBtn>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default LoginPage;
