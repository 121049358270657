import { Box, Button } from "@mui/material"

interface ALNIconBtnProps {
  obj: Object;
  src?: string;
  alt?: string;
  onClick: (obj: Object) => void;
}

function ALNIconBtn ({ obj, src, alt, onClick }: ALNIconBtnProps) {
  return (
      <Button variant="text" onClick={() => onClick(obj)}>
        <Box
          component="img"
          src={src}
          alt={alt}
        />
      </Button>
  );

} 

export default ALNIconBtn;