/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GetErrorMessages200ResponseInner,
  GetProductCount200ResponseInner,
} from '../models/index';
import {
    GetErrorMessages200ResponseInnerFromJSON,
    GetErrorMessages200ResponseInnerToJSON,
    GetProductCount200ResponseInnerFromJSON,
    GetProductCount200ResponseInnerToJSON,
} from '../models/index';

export interface GetErrorMessagesRequest {
    authorization: string;
}

export interface GetErrorMessages0Request {
    authorization: string;
}

export interface GetLogFileRequest {
    authorization: string;
    name: string;
}

export interface GetLogFileNamesRequest {
    authorization: string;
}

export interface GetLogFileNames0Request {
    authorization: string;
}

export interface GetLogFile0Request {
    authorization: string;
    name: string;
}

export interface GetProductCountRequest {
    authorization: string;
}

export interface GetProductCount0Request {
    authorization: string;
}

export interface RemoveErrorMessageRequest {
    authorization: string;
    message: string;
}

export interface RemoveErrorMessage0Request {
    authorization: string;
    message: string;
}

/**
 * 
 */
export class SupportServiceApi extends runtime.BaseAPI {

    /**
     */
    async getErrorMessagesRaw(requestParameters: GetErrorMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetErrorMessages200ResponseInner>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getErrorMessages().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/support/getErrorMessages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetErrorMessages200ResponseInnerFromJSON));
    }

    /**
     */
    async getErrorMessages(authorization: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetErrorMessages200ResponseInner>> {
        const response = await this.getErrorMessagesRaw({ authorization: authorization }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getErrorMessages_1Raw(requestParameters: GetErrorMessages0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetErrorMessages200ResponseInner>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getErrorMessages_1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/support/getErrorMessages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetErrorMessages200ResponseInnerFromJSON));
    }

    /**
     */
    async getErrorMessages_1(authorization: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetErrorMessages200ResponseInner>> {
        const response = await this.getErrorMessages_1Raw({ authorization: authorization }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLogFileRaw(requestParameters: GetLogFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getLogFile().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling getLogFile().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/support/getLogFile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getLogFile(authorization: string, name: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getLogFileRaw({ authorization: authorization, name: name }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLogFileNamesRaw(requestParameters: GetLogFileNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getLogFileNames().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/support/getLogFileNames`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getLogFileNames(authorization: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getLogFileNamesRaw({ authorization: authorization }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLogFileNames_2Raw(requestParameters: GetLogFileNames0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getLogFileNames_2().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/support/getLogFileNames`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getLogFileNames_2(authorization: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getLogFileNames_2Raw({ authorization: authorization }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLogFile_3Raw(requestParameters: GetLogFile0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getLogFile_3().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling getLogFile_3().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/support/getLogFile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getLogFile_3(authorization: string, name: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getLogFile_3Raw({ authorization: authorization, name: name }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProductCountRaw(requestParameters: GetProductCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetProductCount200ResponseInner>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getProductCount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/support/getProductCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProductCount200ResponseInnerFromJSON));
    }

    /**
     */
    async getProductCount(authorization: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetProductCount200ResponseInner>> {
        const response = await this.getProductCountRaw({ authorization: authorization }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProductCount_4Raw(requestParameters: GetProductCount0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetProductCount200ResponseInner>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getProductCount_4().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/support/getProductCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetProductCount200ResponseInnerFromJSON));
    }

    /**
     */
    async getProductCount_4(authorization: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetProductCount200ResponseInner>> {
        const response = await this.getProductCount_4Raw({ authorization: authorization }, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeErrorMessageRaw(requestParameters: RemoveErrorMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling removeErrorMessage().'
            );
        }

        if (requestParameters['message'] == null) {
            throw new runtime.RequiredError(
                'message',
                'Required parameter "message" was null or undefined when calling removeErrorMessage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['message'] != null) {
            queryParameters['message'] = requestParameters['message'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/support/removeErrorMessage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removeErrorMessage(authorization: string, message: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeErrorMessageRaw({ authorization: authorization, message: message }, initOverrides);
    }

    /**
     */
    async removeErrorMessage_5Raw(requestParameters: RemoveErrorMessage0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling removeErrorMessage_5().'
            );
        }

        if (requestParameters['message'] == null) {
            throw new runtime.RequiredError(
                'message',
                'Required parameter "message" was null or undefined when calling removeErrorMessage_5().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['message'] != null) {
            queryParameters['message'] = requestParameters['message'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/support/removeErrorMessage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removeErrorMessage_5(authorization: string, message: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeErrorMessage_5Raw({ authorization: authorization, message: message }, initOverrides);
    }

}
