import React from 'react';
import {  
    TextField,
    Autocomplete,
    Stack
} from '@mui/material';

const EmailStatues = [ 'AutoProcessed', 'ManualProcessed', 'NotProcessed', 'Ignored', 'IssueReported', 'All'];

interface ALNMultiSelectProps {
  onChange: (event: any, newValues: any) => void;
}

function ALNMultiSelect ({ onChange }: ALNMultiSelectProps) {
  return (
    <Stack spacing={3}>
      <Autocomplete
          multiple
          id="tags-standard"
          options={EmailStatues}
          getOptionLabel={(option) => option}
          defaultValue={[EmailStatues[2], EmailStatues[0]]}
          filterSelectedOptions
          onChange={onChange}
          renderInput={(params) => (
          <TextField
              {...params}
              label="Email Status"
              placeholder=""
          />
          )}
      />
    </Stack>
  );
}
export default ALNMultiSelect;
