import {
  Box,
  ToggleButton,
  ToggleButtonGroup
} from "@mui/material";
import { ReactComponent as AddPlus } from "../assets/images/addPlug.svg";
import { ReactComponent as AlertIcon } from "../assets/images/alert-icon.svg";
import { ReactComponent as DeleteIcon } from "../assets/images/delete-icon.svg";
import { ReactComponent as ArrowBackIcon } from "../assets/images/arrowback-icon.svg";
import React, { useState } from "react";
import { Services } from "../Services";
import { Global } from "../Global";
import {
  EmailParseRequest,
  EmailData,
  EmailParseResult,
} from "../services/models";
import { DisplayEmail } from "./DisplayEmail";
import ALNBtn from "../components/form/ALNBtn/ALNBtn";
import ALNLabel from "../components/label/ALNLabel";
import ALNTextField from "../components/form/ALNInput/ALNTextField";
import { ALNColors } from "../utils/color";

interface Product {
  id: number;
  name: string;
  quantity: number;
}

function QuoteTool() {
  const [products, setProducts] = useState<Product[]>([
    {
      id: Date.now(),
      name: "",
      quantity: 0,
    },
  ]);

  const [results, setResults] = useState<string | undefined>();
  const [fullResults, setFullResults] = useState<string | undefined>();
  const [resultType, setResultType] = useState("email");
  const [emailReply, setEmailReply] = useState<EmailData | undefined>();
  const [resultsFlag, setResultsFlag] = useState<boolean>(true);

  const [emailParseResults, setEmailParseResults] = useState<
    EmailParseResult | undefined
  >();

  if (
    products.length === 0 ||
    products.every((product) => product.name !== "" && product.quantity !== 0)
  ) {
    setProducts([
      ...products,
      {
        id: Date.now(),
        name: "",
        quantity: 0,
      },
    ]);
  }

  const handleDeleteProduct = (id: number) => {
    setProducts(products.filter((product) => product.id !== id));
  };

  const handleSubmitFull = () => {
    setEmailParseResults(undefined);
    setResults(undefined);
    setFullResults(undefined);
    setEmailReply(undefined);
    let parseRequest: EmailParseRequest = {
      mailboxName: Global.getGlobal().CurrentMailbox!,
      email: {
        subject: "Quote Request",
        body:
          "Please provide quote for following \n\n" +
          products
            .filter((p) => p.quantity !== 0)
            .map((p) => p.name + " qty: " + p.quantity)
            .join("\n"),
        from: { address: "test@test.com", name: "Joe" },
      },
    };

    Services.testService
      .parseEmail(Global.getJwtToken()!, parseRequest)
      .then((res) => {
        setFullResults(JSON.stringify(res, null, 2));
        setResults(JSON.stringify(res.productInfo));
        setResultsFlag(false);
        setEmailReply(res.email?.reply);
        setEmailParseResults(res);
      });
  };

  const handleClear = () => {
    setProducts([]);
    setResults(undefined);
  };

  function addRandom(): void {
    const randomQuantity = Math.floor(Math.random() * 1000) + 1;
    Services.testService
      .getRandomProductNames(
        Global.getJwtToken()!,
        Global.getGlobal().CurrentMailbox!,
      )
      .then((res) => {
        let p = [
          ...products,
          {
            id: Date.now(),
            name: res[Math.floor(Math.random() * res.length)],
            quantity: randomQuantity,
          },
        ];

        p.sort((a, b) => (a.quantity === 0 ? 1 : b.quantity === 0 ? -1 : 0));
        setProducts(p);
      });
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "25px 50px",
        background: "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)"
      }}
    >
      <ALNLabel sx={{fontSize: "10px", lineHeight: "12px", fontWeight: 500, width: "fit-content"}}>
        {resultsFlag || !results ? 'Email > Quote Tool' : 'Email > Quote Tool > Quote Tool Results'}
      </ALNLabel>
      {resultsFlag &&
      <Box
        sx={{
          background: ALNColors.white,
          padding: "25px 25px", 
          borderRadius : "16px",
          border: "1px solid "+ALNColors.background_gray,
          height: 'calc(100vh - 170px)',
        }}
      >
        <Box
          sx={{
            flex: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            paddtingTop: "0px",
          }}
        >
          {results && 
            <ArrowBackIcon
              style={{
                marginRight: "14px",
                cursor: "pointer",
                paddingTop: "11px"
              }}
              onClick={() => {
                setResultsFlag(false);
              }}
            />
          }
          <ALNLabel sx={{ fontSize: "24px", paddingTop: "10px", lineHeight: "18px", fontWeight: 700 }}>
            Quote Tool
          </ALNLabel>
        </Box>
        <Box
          sx={{ 
            display: "flex",
            flexDirection: "column",
            paddingRight: "34px",
            paddingLeft: "34px",
           }}
        >
          <Box>
            {products.map((product, index) => (
              <Box key={product.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px"
                }}
              >
                <Box>
                  <ALNLabel sx={{ marginBottom: "5px", marginTop: "20px", fontWeight: 500, fontSize: "14px", lineHeight: "20px" }}>
                    Product Name
                  </ALNLabel>
                  <ALNTextField
                    variant="outlined"
                    sx={{ width: "344px", height: "40px", paddingRight: "10px" }}
                    value={product.name}
                    onChange={(currentValue) => {
                      const newProducts = [...products];
                      newProducts[index].name = currentValue;
                      setProducts(newProducts);
                    }}
                  />
                </Box>
                <Box>
                  <ALNLabel sx={{ marginBottom: "5px", marginTop: "20px", fontWeight: 500, fontSize: "14px", lineHeight: "20px" }}>
                    Quantity
                  </ALNLabel>
                  <ALNTextField
                    variant="outlined"
                    type="number"
                    sx={{ width: "344px", height: "40px", paddingRight: "0px" }}
                    value={product.quantity}
                    onChange={(currentValue) => {
                      const newProducts = [...products];
                      newProducts[index].quantity = currentValue
                        ? Number(currentValue)
                        : 0;
                      setProducts(newProducts);
                    }}
                  />
                </Box>
                <ALNBtn 
                  sx= {{
                    marginTop: "55px",
                    paddingLeft: "0px",
                    marginLeft: "0px",
                    marginRight: "0px"
                  }}                
                  variant="text" 
                  onClick = { () => handleDeleteProduct(product.id) }>
                  <DeleteIcon />
                </ALNBtn>
              </Box>
            ))}
          </Box>
          <Box display="flex" gap="10px" marginTop="40px">
            <ALNBtn variant="contained" onClick = { handleSubmitFull } sx={{ background: ALNColors.primary_btn, textTransform: "uppercase" }}>Submit</ALNBtn>
            <ALNBtn onClick = { addRandom } sx={{ color: ALNColors.primary_btn, textTransform: "uppercase" }}>Add Random</ALNBtn>
            <ALNBtn onClick = { handleClear } sx={{ color: ALNColors.primary_btn, textTransform: "uppercase" }}>Reset</ALNBtn>
          </Box>
        </Box>
      </Box>
      }
      {!resultsFlag && results && 
        <Box 
          sx={{ 
            background: ALNColors.white,
            padding: "25px 25px", 
            borderRadius : "16px",
            border: "1px solid "+ALNColors.background_gray,
            height: 'calc(100vh - 170px)',
          }} 
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddtingTop: "0px",
              marginBottom: "24px"
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <ArrowBackIcon
                style={{
                  marginRight: "14px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setResultsFlag(true);
                }}
              />
              <ALNLabel sx={{ fontSize: "24px", lineHeight: "18px", fontWeight: 700 }}>
                Quote Tool Results
              </ALNLabel>
            </Box>
            <ALNBtn
              sx={{
                borderRadius: '8px',
                padding: '8px 16px',
                marginRight: "28px",
                bgcolor: ALNColors.primary_btn,
                fontFamily: "OpenSans",
                fontSize: '15px',
                fontWeight: "fontWeightMedium",
                lineHeight: '24px',
                letterSpacing: 0.02,
                textAlign: "left",
                color: ALNColors.white,
                cursor: "pointer",
              }}
              onClick={() => {
                handleSubmitFull();
              }}
            >
              Start a new quote &nbsp;<AddPlus />
            </ALNBtn>
          </Box>
          <Box
            sx={{
              paddingLeft: "34px",
              paddingRight: "34px",
              gap: "12px",
            }}
          >
            <ToggleButtonGroup
              value={resultType}
              exclusive
              onChange={ (e, newValue) => {
                setResultType(newValue); 
              }}
              sx= {{
                width: "450px",
                padding: "5px",
                fontSize: "14px",
                fontWeight: 500,
                lineHeight: "20px",
                background: ALNColors.background_very_gray
              }}
            >
              <ToggleButton
                sx={{
                  minWidth: 150,
                  border: "none",
                  borderRadius: "8px",
                  color: ALNColors.border_very_gray,
                  "&.Mui-selected": {
                    backgroundColor: ALNColors.indigo,
                    color: ALNColors.white,
                    "&:hover": {
                      backgroundColor: ALNColors.white_blue,
                    },
                  },
                }}
                value="email"
              >
                Email
              </ToggleButton>
              <ToggleButton
                sx={{
                  minWidth: 150,
                  borderRadius: "8px",
                  color: ALNColors.border_very_gray,
                  border: "none",
                  "&.Mui-selected": {
                    backgroundColor: ALNColors.indigo, // Custom selected background color
                    color: ALNColors.white, // White text when selected
                    "&:hover": {
                      backgroundColor: ALNColors.white_blue, // Darker blue on hover
                    },
                  },
                }}
                value="data"
              >
                Data
              </ToggleButton>
              <ToggleButton
                sx={{
                  minWidth: 150,
                  border: "none",
                  borderRadius: "8px",
                  color: ALNColors.border_very_gray,
                  "&.Mui-selected": {
                    backgroundColor: ALNColors.indigo, // Custom selected background color
                    color: ALNColors.white, // White text when selected
                    "&:hover": {
                      backgroundColor: ALNColors.white_blue, // Darker blue on hover
                    },
                  },
                }}
                value="full"
              >
                Full
              </ToggleButton>
            </ToggleButtonGroup>
            {emailParseResults && resultType === "email" && emailReply &&
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              sx={{
                marginTop: "24px"
              }}
            >
              <Box
                sx={{
                  borderBottom: `2px solid ${ALNColors.border_gray}`,
                  paddingBottom: "10px",
                  width: "30%",
                }}
              >
                <ALNLabel sx={{ fontSize: "12px", lineHeight: "21px", fontWeight: 600, color: ALNColors.black_gray }}>
                  Time Taken (SECONDS)
                </ALNLabel>
                <ALNLabel sx={{ fontSize: "14px", lineHeight: "21px", fontWeight: 600, color: ALNColors.green_very_dark }}>
                  {emailParseResults.email?.timeTakenInSeconds?.toFixed(3)}{" "}
                </ALNLabel>
              </Box>
              <Box
                sx={{
                  borderBottom: `2px solid ${ALNColors.border_gray}`,
                  paddingBottom: "10px",
                  width: "30%",
                }}
              >
                <ALNLabel sx={{ fontSize: "12px", lineHeight: "21px", fontWeight: 600, color: ALNColors.black_gray }}>
                  ORDER TOTAL
                </ALNLabel>
                <ALNLabel sx={{ fontSize: "14px", lineHeight: "21px", fontWeight: 600, color: ALNColors.green_very_dark }}>
                  ${emailParseResults.email?.totalValue?.toFixed(2)}
                </ALNLabel>
              </Box>
              <Box
                sx={{
                  borderBottom: `2px solid ${ALNColors.border_gray}`,
                  paddingBottom: "10px",
                  width: "30%",
                }}
              >
                <ALNLabel sx={{ fontSize: "12px", lineHeight: "21px", fontWeight: 600, color: ALNColors.black_gray }}>
                  ACTION PROPOSED
                </ALNLabel>
                <ALNLabel sx={{ fontSize: "14px", lineHeight: "21px", fontWeight: 600, color: ALNColors.green_very_dark }}>
                  {emailParseResults.email?.emailStatus}
                </ALNLabel>
              </Box>
            </Box>
            }
            <Box
              component="pre"
              sx={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                maxHeight: "87%",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {resultType === "email" && emailReply && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderBottom: "1px solid "+ALNColors.background_gray
                  }}
                >
                  {emailParseResults && (
                    <Box
                    >
                      {emailParseResults.email?.cannotReplyReason &&
                        emailParseResults.email?.cannotReplyReason?.length >
                          0 && (
                          <Box>
                            <ALNLabel
                              sx={{
                                marginTop: "12px",
                                fontWeight: 600,
                                lineHeight: "21px",
                                color: ALNColors.black_gray
                              }}
                            >
                              ISSUES:
                            </ALNLabel>
                            <ALNLabel
                              sx={{
                                textAlign: "left",
                                marginTop: "12px",
                              }}
                            >
                              {emailParseResults.email?.cannotReplyReason?.map(
                                (reason) => (
                                  <Box key={reason} component="span" display="block"
                                    sx={{
                                      marginBottom: "12px",
                                      display: "flex",
                                    }}
                                  >
                                    <AlertIcon />
                                    <ALNLabel sx={{ paddingLeft: "10px", paddingTop: "1px", fontSize: "14px", lineHeight: "21px", fontWeight: 600, color: ALNColors.green_very_dark }}>
                                       {reason}
                                    </ALNLabel>
                                  </Box>
                                ),
                              )}
                            </ALNLabel>
                          </Box>
                        )}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
          {resultType === "email" && emailReply && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                background: ALNColors.background_very_gray,
                borderRadius: "8px",
                marginRight: "28px",
              }}
            >
              {emailReply && (
                <DisplayEmail email={emailReply} isReply={true} />
              )}
            </Box>
          )}
          {resultType === "data" && 
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                background: ALNColors.background_very_gray,
                borderRadius: "8px",
                padding: '30px',
                paddingLeft: "45px",
                marginRight: "28px",
              }}
            >
            {results}
          </Box>
          }
          {resultType === "full" && 
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              background: ALNColors.background_very_gray,
              borderRadius: "8px",
              padding: '30px',
              paddingLeft: "45px",
              marginRight: "28px",
            }}
          >
            {fullResults}
          </Box>
          }
        </Box>
      }
    </Box>
  );
};

export default QuoteTool;
