import React from 'react';
import { Box } from '@mui/material';
import { ALNColors } from '../../utils/color';
interface ALNModalHeaderProps {
  children?: React.ReactNode
};
function ALNModalHeader ({ children }: ALNModalHeaderProps) {
  return(
    <Box 
      sx={{
        padding: 2,
        borderTopLeftRadius: 2,
        borderTopRightRadius: 2,
        backgroundColor: ALNColors.primary_btn,
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        justifyContent: 'space-between'
      }}
    >
      {children}
    </Box>
  )
}

export default ALNModalHeader;