/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmailDebugInfo } from './EmailDebugInfo';
import {
    EmailDebugInfoFromJSON,
    EmailDebugInfoFromJSONTyped,
    EmailDebugInfoToJSON,
} from './EmailDebugInfo';
import type { EmailStatus } from './EmailStatus';
import {
    EmailStatusFromJSON,
    EmailStatusFromJSONTyped,
    EmailStatusToJSON,
} from './EmailStatus';
import type { EmailData } from './EmailData';
import {
    EmailDataFromJSON,
    EmailDataFromJSONTyped,
    EmailDataToJSON,
} from './EmailData';

/**
 * 
 * @export
 * @interface Email
 */
export interface Email {
    /**
     * 
     * @type {any}
     * @memberof Email
     */
    id?: any | null;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof Email
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    modifiedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof Email
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    companyName?: string;
    /**
     * 
     * @type {Date}
     * @memberof Email
     */
    emailDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    mailbox?: string;
    /**
     * 
     * @type {EmailData}
     * @memberof Email
     */
    inboundEmail?: EmailData;
    /**
     * 
     * @type {EmailData}
     * @memberof Email
     */
    reply?: EmailData;
    /**
     * 
     * @type {EmailData}
     * @memberof Email
     */
    generatedReply?: EmailData;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    processedOnServer?: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    processedOnVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    forwardAddressFromRule?: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    error?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Email
     */
    replyAIGenerated?: boolean;
    /**
     * 
     * @type {EmailStatus}
     * @memberof Email
     */
    emailStatus?: EmailStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof Email
     */
    cannotReplyReason?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    annotations?: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    editedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof Email
     */
    editedOn?: Date;
    /**
     * 
     * @type {EmailDebugInfo}
     * @memberof Email
     */
    debugInfo?: EmailDebugInfo;
    /**
     * 
     * @type {EmailStatus}
     * @memberof Email
     */
    beforeIssueStatus?: EmailStatus;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    issuesNotes?: string;
    /**
     * 
     * @type {string}
     * @memberof Email
     */
    issueResolvedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof Email
     */
    issueResolvedOn?: Date;
    /**
     * 
     * @type {number}
     * @memberof Email
     */
    timeTakenInSeconds?: number;
    /**
     * 
     * @type {number}
     * @memberof Email
     */
    totalValue?: number;
}



/**
 * Check if a given object implements the Email interface.
 */
export function instanceOfEmail(value: object): value is Email {
    return true;
}

export function EmailFromJSON(json: any): Email {
    return EmailFromJSONTyped(json, false);
}

export function EmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): Email {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'createdDate': json['createdDate'] == null ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': json['modifiedBy'] == null ? undefined : json['modifiedBy'],
        'modifiedDate': json['modifiedDate'] == null ? undefined : (new Date(json['modifiedDate'])),
        'companyName': json['companyName'] == null ? undefined : json['companyName'],
        'emailDate': json['emailDate'] == null ? undefined : (new Date(json['emailDate'])),
        'mailbox': json['mailbox'] == null ? undefined : json['mailbox'],
        'inboundEmail': json['inboundEmail'] == null ? undefined : EmailDataFromJSON(json['inboundEmail']),
        'reply': json['reply'] == null ? undefined : EmailDataFromJSON(json['reply']),
        'generatedReply': json['generatedReply'] == null ? undefined : EmailDataFromJSON(json['generatedReply']),
        'processedOnServer': json['processedOnServer'] == null ? undefined : json['processedOnServer'],
        'processedOnVersion': json['processedOnVersion'] == null ? undefined : json['processedOnVersion'],
        'forwardAddressFromRule': json['forwardAddressFromRule'] == null ? undefined : json['forwardAddressFromRule'],
        'error': json['error'] == null ? undefined : json['error'],
        'replyAIGenerated': json['replyAIGenerated'] == null ? undefined : json['replyAIGenerated'],
        'emailStatus': json['emailStatus'] == null ? undefined : EmailStatusFromJSON(json['emailStatus']),
        'cannotReplyReason': json['cannotReplyReason'] == null ? undefined : json['cannotReplyReason'],
        'annotations': json['annotations'] == null ? undefined : json['annotations'],
        'editedBy': json['editedBy'] == null ? undefined : json['editedBy'],
        'editedOn': json['editedOn'] == null ? undefined : (new Date(json['editedOn'])),
        'debugInfo': json['debugInfo'] == null ? undefined : EmailDebugInfoFromJSON(json['debugInfo']),
        'beforeIssueStatus': json['beforeIssueStatus'] == null ? undefined : EmailStatusFromJSON(json['beforeIssueStatus']),
        'issuesNotes': json['issuesNotes'] == null ? undefined : json['issuesNotes'],
        'issueResolvedBy': json['issueResolvedBy'] == null ? undefined : json['issueResolvedBy'],
        'issueResolvedOn': json['issueResolvedOn'] == null ? undefined : (new Date(json['issueResolvedOn'])),
        'timeTakenInSeconds': json['timeTakenInSeconds'] == null ? undefined : json['timeTakenInSeconds'],
        'totalValue': json['totalValue'] == null ? undefined : json['totalValue'],
    };
}

export function EmailToJSON(value?: Email | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'createdBy': value['createdBy'],
        'createdDate': value['createdDate'] == null ? undefined : ((value['createdDate']).toISOString()),
        'modifiedBy': value['modifiedBy'],
        'modifiedDate': value['modifiedDate'] == null ? undefined : ((value['modifiedDate']).toISOString()),
        'companyName': value['companyName'],
        'emailDate': value['emailDate'] == null ? undefined : ((value['emailDate']).toISOString()),
        'mailbox': value['mailbox'],
        'inboundEmail': EmailDataToJSON(value['inboundEmail']),
        'reply': EmailDataToJSON(value['reply']),
        'generatedReply': EmailDataToJSON(value['generatedReply']),
        'processedOnServer': value['processedOnServer'],
        'processedOnVersion': value['processedOnVersion'],
        'forwardAddressFromRule': value['forwardAddressFromRule'],
        'error': value['error'],
        'replyAIGenerated': value['replyAIGenerated'],
        'emailStatus': EmailStatusToJSON(value['emailStatus']),
        'cannotReplyReason': value['cannotReplyReason'],
        'annotations': value['annotations'],
        'editedBy': value['editedBy'],
        'editedOn': value['editedOn'] == null ? undefined : ((value['editedOn']).toISOString()),
        'debugInfo': EmailDebugInfoToJSON(value['debugInfo']),
        'beforeIssueStatus': EmailStatusToJSON(value['beforeIssueStatus']),
        'issuesNotes': value['issuesNotes'],
        'issueResolvedBy': value['issueResolvedBy'],
        'issueResolvedOn': value['issueResolvedOn'] == null ? undefined : ((value['issueResolvedOn']).toISOString()),
        'timeTakenInSeconds': value['timeTakenInSeconds'],
        'totalValue': value['totalValue'],
    };
}

