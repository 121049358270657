import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { ReactComponent as ArrowBackIcon } from "../assets/images/arrowback-icon.svg";
import React, { useEffect, useState } from "react";
import { EmailStatus } from "../services/models/EmailStatus";
import { Email } from "../services/models/Email";
import { ReactComponent as BlockUserIcon } from "../assets/images/blockuser-icon.svg";
import { ReactComponent as ReportIssueIcon } from "../assets/images/reportissue-icon.svg";
import { ReactComponent as OpenParseToolIcon } from "../assets/images/openparsetool-icon.svg";
import { ReactComponent as AlertIcon } from "../assets/images/alert-icon.svg";
import { ReactComponent as CloseIcon } from "../assets/images/close-icon.svg";
import { Services } from "../Services";
import { Global } from "../Global";
import { DisplayEmail } from "../pages/DisplayEmail";
import DialogEmailParseTool from "../pages/email/DialogEmailParseTool";
import ALNEmailBtn from "./form/ALNBtn/ALNEmailBtn";
import ALNInput from "./form/ALNInput/ALNInput";
import ALNLabel from "./label/ALNLabel";
import avatarImg from "../assets/images/avatar.png";
import { ALNColors } from "../utils/color";
// import { User } from "../services/models/User";

interface EmailMainPartProps {
  email: Email;
  onClose: () => void;

  addedTab?: (email: Email) => JSX.Element;
  addedTabTitle?: string;
}

function EmailMainPart(props: EmailMainPartProps) {
  const [iconSrc, setIconSrc] = useState<string | null>(null);
  const [iconUrl, setIconUrl] = useState<string | null>(
    "email-not-respond-icon.png",
  );
  const [colorVal, setColorVal] = useState<string>(`${ALNColors.yellow}`);
  const [emailStatus, setEmailStatus] = useState<string>("");
  // const [user, setUser] = useState<User>({});
  const [issue, setIssue] = useState<string>("");

  const [reportIssue, setReportIssue] = useState<boolean>(false);

  const [page, setPage] = useState<"incoming" | "reply" | "details" | "added">(
    "incoming",
  );

  const [openParseTool, setOpenParseTool] = useState<boolean>(false);

  useEffect(() => {
    Services.userService
      .getMe(Global.getJwtToken()!)
      .then(() => {
      // .then((user: any) => {
      //   setUser(user);
      })
      .catch((e: any) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    setPage("incoming");
    setOpenParseTool(false);
  }, [props.email]);

  useEffect(() => {
    import(`../assets/images/${iconUrl}`)
      .then((module) => setIconSrc(module.default))
      .catch((err) => console.error("Error loading icon:", err));
  }, [iconUrl]);

  useEffect(() => {
    const emailStatus = props.email.emailStatus;
    switch (emailStatus) {
      case EmailStatus.AutoProcessed:
        setIconUrl("email-auto-respond-icon.png");
        setColorVal(`${ALNColors.email_auto}`);
        setEmailStatus("Auto");
        break;
      case EmailStatus.ManualProcessed:
        setIconUrl("email-manual-respond-icon.png");
        setColorVal(`${ALNColors.email_manual}`);
        setEmailStatus("Manually");
        break;
      case EmailStatus.NotProcessed:
        setIconUrl("email-not-respond-icon.png");
        setColorVal(`${ALNColors.email_couldnotrespond}`);
        setEmailStatus("Could Not Respond");
        break;
      case EmailStatus.Ignored:
        setIconUrl("email-ignore-icon.png");
        setColorVal(`${ALNColors.email_ignored}`);
        setEmailStatus("Ignored");
        break;
      case EmailStatus.IssueReported:
        setIconUrl("email-annotated-icon.png");
        setColorVal(`${ALNColors.email_withissue}`);
        setEmailStatus("Issue Reported");
        break;
      default:
        setIconUrl("email-not-respond-icon.png");
        setColorVal(`${ALNColors.email_couldnotrespond}`);
        setEmailStatus("Could Not Respond");
    }
  }, [props.email.emailStatus]);

  const handleClose = () => {
    props.onClose();
    setOpenParseTool(false);
  };

  let showTotalValue: boolean =
    props.email.totalValue !== undefined && props.email.totalValue !== 0;
  let showTimeTaken: boolean =
    props.email.timeTakenInSeconds !== undefined &&
    props.email.timeTakenInSeconds !== 0;

  return (
    <Box
      sx={{
        backgroundColor: ALNColors.white,
        paddingLeft: "12px",
        paddingRight: "12px",
        paddingTop: "0px",
        paddingbottom: "0px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0px",
          }}
        >
          <Box>
            <Box
              sx={{
                flex: 2,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                paddtingTop: "0px",
              }}
            >
              <ArrowBackIcon
                style={{
                  marginRight: "14px",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              />
              <ALNLabel
                sx={{
                  fontSize: "25px",
                  lineHeight: "50px",
                  fontWeight: 600,
                  padding: "0px",
                  width: "fit-content",
                  marginRight: "14px",
                }}
              >
                Quote Request
              </ALNLabel>
              <Box sx={{
                  display: "flex",
                  justifyContent: "start",
                  gap: "8px",
                  width: "fit-content",
                  border: "1px solid #000000",
                  borderWidth: "1px",
                  borderRadius: "100px",
                  padding:"5px 12px",
                }}
                style={{ borderColor: colorVal || ALNColors.black }}
              >          
                {iconSrc && 
                  <Box
                    component="img"
                    src={iconSrc}
                    alt={emailStatus}
                  />
                }
                <ALNLabel
                  sx={{
                    fontFamily: '"OpenSans"',
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '21px',
                    letterSpacing: '0.02em',
                    textAlign: 'left',
                    color: colorVal || ALNColors.black,
                    textTransform: 'uppercase'
                  }}
                >
                  {emailStatus}
                </ALNLabel>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
                <Box
                  component="img"
                  src={avatarImg}
                  alt="avatar"
                  sx={{
                    borderRadius: "50%",
                    width: "38px",
                    height: "38px",
                    marginLeft: "30px",
                    marginRight: "12px",
                  }}
                />
                <Box>
                <Box sx={{
                  display: "flex"
                }}>
                  <ALNLabel
                    sx={{
                      fontFamily: "OpenSans",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "18px",
                      letterSpacing: 0.02,
                      textAlign: "left",
                      marginBottom: "8px",
                      color: ALNColors.gray,
                      "& span": {},
                    }}
                  >
                    From:
                  </ALNLabel>
                  <ALNLabel
                    sx={{
                      fontFamily: "Open Sans, sans-serif",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      letterSpacing: "0.02px",
                      textAlign: "left",
                      marginLeft: "5px",
                      color: ALNColors.black,
                    }}
                  >
                    {props.email.inboundEmail!.from?.address}
                  </ALNLabel>
                </Box>
                <Box
                  sx={{
                    display: "flex"
                  }}
                >
                  <ALNLabel
                    sx={{
                      fontFamily: "OpenSans",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "18px",
                      letterSpacing: 0.02,
                      textAlign: "left",
                      color: ALNColors.gray,
                      "& span": {},
                    }}
                  >
                    To: 
                  </ALNLabel>
                  <ALNLabel
                    sx={{
                      fontFamily: "Open Sans, sans-serif",
                      fontSize: "12px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      letterSpacing: "0.02px",
                      textAlign: "left",
                      marginLeft: "5px",
                      color: ALNColors.black,
                    }}
                  >
                    {props.email?.inboundEmail?.to?.[0]?.address ?? 'No address'}
                  </ALNLabel>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {!reportIssue && (
            <Box 
              sx={{ 
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ALNEmailBtn
                sx={{
                  borderRadius: "8px",
                  padding: "12px 14px",
                  bgcolor: ALNColors.primary_btn,
                  color: ALNColors.white,
                  cursor: "pointer",
                  marginRight: "10px",
                  display: "flex"
                }}
                onClick={() => {
                  Services.emailAddressRuleService.blockUser(
                    Global.getJwtToken()!,
                    props.email.inboundEmail!.from!.address!,
                  );
                  props.onClose();
                }}
              >
                <ALNLabel
                  sx={{
                    fontFamily: "OpenSans",
                    fontSize: "14px",
                    fontWeight: 590,
                    lineHeight: "21px",
                    letterSpacing: 0.02,
                    textAlign: "left",
                  }}
                >
                  Block User
                </ALNLabel>
                <Box
                  sx={{
                    paddingTop: "2px",
                    paddingLeft: "12px",
                  }}
                >
                  <BlockUserIcon />
                </Box>
              </ALNEmailBtn>
              {props.email.emailStatus !== EmailStatus.Ignored && (
                <ALNEmailBtn
                  sx={{
                    borderRadius: "8px",
                    padding: "12px 14px",
                    bgcolor: ALNColors.primary_btn,
                    color: ALNColors.white,
                    cursor: "pointer",
                    marginRight: "10px",
                    display: "flex"
                  }}
                  onClick={() => {
                    Services.emailService.ignoreEmail(
                      Global.getJwtToken()!,
                      Global.getGlobal().CurrentMailbox!,
                      props.email.id,
                    );
                    props.onClose();
                  }}
                >
                  <ALNLabel
                    sx={{
                      fontFamily: "OpenSans",
                      fontSize: "14px",
                      fontWeight: 590,
                      lineHeight: "21px",
                      letterSpacing: 0.02,
                      textAlign: "left",
                    }}
                  >
                    Ignore Email
                  </ALNLabel>
                  <Box
                    sx={{
                      paddingTop: "2px",
                      paddingLeft: "12px",
                    }}
                  >
                    <BlockUserIcon />
                  </Box>
                </ALNEmailBtn>
              )}

              {props.email.emailStatus === EmailStatus.IssueReported && (
                <ALNEmailBtn
                  sx={{
                    borderRadius: "8px",
                    padding: "12px 14px",
                    bgcolor: ALNColors.primary_btn,
                    color: ALNColors.white,
                    cursor: "pointer",
                    marginRight: "10px",
                    display: "flex"
                  }}
                  onClick={() => {
                    Services.emailService.clearIssue(
                      Global.getJwtToken()!,
                      Global.getGlobal().CurrentMailbox!,
                      props.email.id,
                    );
                    props.onClose();
                  }}
                >
                  <ALNLabel
                    sx={{
                      fontFamily: "OpenSans",
                      fontSize: "14px",
                      fontWeight: 590,
                      lineHeight: "21px",
                      letterSpacing: 0.02,
                      textAlign: "left",
                    }}
                  >
                    Mark Resolved
                  </ALNLabel>
                  <Box
                    sx={{
                      paddingTop: "2px",
                      paddingLeft: "12px",
                    }}
                  >
                    <BlockUserIcon />
                  </Box>
                </ALNEmailBtn>
              )}

              {props.email.emailStatus !== EmailStatus.IssueReported && (
                <ALNEmailBtn
                  sx={{
                    borderRadius: "8px",
                    padding: "12px 14px",
                    bgcolor: ALNColors.primary_btn,
                    color: ALNColors.white,
                    cursor: "pointer",
                    marginRight: "10px",
                    display: "flex"
                  }}
                  onClick={() => {
                    setReportIssue(true);
                  }}
                >
                  <ALNLabel
                    sx={{
                      fontFamily: "OpenSans",
                      fontSize: "14px",
                      fontWeight: 590,
                      lineHeight: "21px",
                      letterSpacing: 0.02,
                      textAlign: "left",
                    }}
                  >
                    Report Issue
                  </ALNLabel>
                  <Box
                    sx={{
                      paddingTop: "2px",
                      paddingLeft: "12px",
                    }}
                  >
                    <ReportIssueIcon />
                  </Box>
                </ALNEmailBtn>
              )}

              <ALNEmailBtn
                sx={{
                  borderRadius: "8px",
                  padding: "12px 14px",
                  bgcolor: ALNColors.primary_btn,
                  color: ALNColors.white,
                  cursor: "pointer",
                  marginRight: "10px",
                  display: "flex"
                }}
                onClick={() => {
                  setOpenParseTool(true);
                }}
              >
                <ALNLabel
                  sx={{
                    fontFamily: "OpenSans",
                    fontSize: "14px",
                    fontWeight: 590,
                    lineHeight: "21px",
                    letterSpacing: 0.02,
                    textAlign: "left",
                  }}
                >
                  Open Parse Tool
                </ALNLabel>
                <Box
                  sx={{
                    paddingTop: "2px",
                    paddingLeft: "12px",
                  }}
                >
                  <OpenParseToolIcon />
                </Box>
              </ALNEmailBtn>
          </Box>
          )}

          {reportIssue && (
            <Box>
              <ALNInput
                type="multiline"
                rows={1}
                value={issue}
                onChange={(e) => setIssue(e.target.value)}
              />
              <Box
                sx={{ 
                  paddingTop: "5px",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <Box
                  sx={{
                    borderRadius: '8px',
                    padding: "12px 14px",
                    bgcolor: ALNColors.primary_btn,
                    fontFamily: "OpenSans",
                    fontSize: '14px',
                    fontWeight: 590,
                    lineHeight: '21px',
                    letterSpacing: 0.02,
                    textAlign: "left",
                    color: ALNColors.white,
                    cursor: "pointer",
                    marginRight: "10px"
                  }}
                  onClick={() => {
                    Services.emailService.reportIssue(
                      Global.getJwtToken()!,
                      Global.getGlobal().CurrentMailbox!,
                      props.email.id,
                      issue,
                    );
                    setIssue("");
                    setReportIssue(false);
                    props.onClose();
                  }}
                >
                  Report
                </Box>
                <Box
                  sx={{
                    borderRadius: '8px',
                    padding: "12px 14px",
                    bgcolor: ALNColors.primary_btn,
                    fontFamily: "OpenSans",
                    fontSize: '14px',
                    fontWeight: 590,
                    lineHeight: '21px',
                    letterSpacing: 0.02,
                    textAlign: "left",
                    color: ALNColors.white,
                    cursor: "pointer",
                    marginRight: "10px"
                  }}
                  onClick={() => {
                    setIssue("");
                    setReportIssue(false);
                  }}
                >
                  Cancel
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        sx={{
          marginTop: "20px",
          marginLeft: "30px",
          marginRight: "30px",
        }}
      >
        <Box
          sx={{
            background: ALNColors.green_very_light,
            paddingTop: "10px",
            paddingLeft: "10px",
            borderRadius: "8px",
            height: "72px",
            width: "30%",
          }}
        >
          <ALNLabel sx={{ fontSize: "16px", lineHeight: "21px", fontWeight: 600, color: ALNColors.black_gray }}>
            TOTAL VALUE
          </ALNLabel>
          <ALNLabel sx={{ paddingTop: "4px", fontSize: "18px", lineHeight: "21px", fontWeight: 600, color: ALNColors.green_very_dark }}>
            ${showTotalValue ? props.email.totalValue!.toLocaleString() : 0}
          </ALNLabel>
        </Box>
        <Box
          sx={{
            background: ALNColors.green_very_light,
            paddingTop: "10px",
            paddingLeft: "10px",
            borderRadius: "8px",
            width: "30%",
            height: "72px",
          }}
        >
          <ALNLabel sx={{ fontSize: "16px", lineHeight: "21px", fontWeight: 600, color: ALNColors.black_gray }}>
            TIME(SECONDS)
          </ALNLabel>
          <ALNLabel sx={{ paddingTop: "4px", fontSize: "18px", lineHeight: "21px", fontWeight: 600, color: ALNColors.green_very_dark }}>
            {showTimeTaken ? props.email.timeTakenInSeconds?.toFixed(3) : 0}
          </ALNLabel>
        </Box>
        <Box
          sx={{
            borderBottom: `2px solid ${ALNColors.border_gray}`,
            paddingTop: "10px",
            paddingLeft: "10px",
            width: "30%",
          }}
        >
          <ALNLabel sx={{ fontSize: "16px", lineHeight: "21px", fontWeight: 600, color: ALNColors.black_gray }}>
            RECEIVED
          </ALNLabel>
          <ALNLabel sx={{ paddingTop: "4px", fontSize: "18px", lineHeight: "21px", fontWeight: 600, color: ALNColors.green_very_dark }}>
            {props.email.emailDate?.toLocaleString("en-US", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}
          </ALNLabel>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
        }}
      >
        {/* Toggle Button */}
        <Box
          sx={{
            width: "72%"
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "left",
              marginTop: "45px",
              marginLeft: "30px",
            }}
          >
            <Box
              sx={{
                padding: '5px',
                background: ALNColors.backgroud_very_light_gray,
                borderRadius: "10px",
              }}
            >
              <ToggleButtonGroup
                value={page}
                exclusive
                onChange={(event, newValue) => {
                  setPage(newValue);
                }}
                sx= {{
                  width: "80%",
                  height: "42px",
                }}
              >
                <ToggleButton
                  sx={{
                    minWidth: 150,
                    border: "none",
                    borderRadius: "8px",
                    color: ALNColors.border_very_gray,
                    "&.Mui-selected": {
                      backgroundColor: ALNColors.indigo,
                      color: ALNColors.white,
                      "&:hover": {
                        backgroundColor: ALNColors.white_blue,
                      },
                    },
                  }}
                  value="incoming"
                >
                  Incoming Mail
                </ToggleButton>
                <ToggleButton
                  sx={{
                    minWidth: 150,
                    borderRadius: "8px",
                    color: ALNColors.border_very_gray,
                    border: "none",
                    "&.Mui-selected": {
                      backgroundColor: ALNColors.indigo, // Custom selected background color
                      color: ALNColors.white, // White text when selected
                      "&:hover": {
                        backgroundColor: ALNColors.white_blue, // Darker blue on hover
                      },
                    },
                  }}
                  value="reply"
                >
                  Reply Mail
                </ToggleButton>
                <ToggleButton
                  sx={{
                    minWidth: 150,
                    border: "none",
                    borderRadius: "8px",
                    color: ALNColors.border_very_gray,
                    "&.Mui-selected": {
                      backgroundColor: ALNColors.indigo, // Custom selected background color
                      color: ALNColors.white, // White text when selected
                      "&:hover": {
                        backgroundColor: ALNColors.white_blue, // Darker blue on hover
                      },
                    },
                  }}
                  value="details"
                >
                  Details
                </ToggleButton>
                {props.addedTab && (
                  <ToggleButton
                    sx={{
                      minWidth: 150,
                      border: "none",
                      borderRadius: "8px",
                      color: ALNColors.border_very_gray,
                      marginLeft: "2px",
                      "&.Mui-selected": {
                        backgroundColor: ALNColors.indigo, // Custom selected background color
                        color: ALNColors.white, // White text when selected
                        "&:hover": {
                          backgroundColor: ALNColors.white_blue, // Darker blue on hover
                        },
                      },
                    }}
                    value="added"
                  >
                    {props.addedTabTitle ?? "Extra"}
                  </ToggleButton>
                )}
              </ToggleButtonGroup>
            </Box>
          </Box>
          {page === "incoming" && (
            <Box
              sx={{ 
                overflowY: "hidden",
                marginTop: "10px",
                marginBottom: "10px",
                marginLeft: "30px",
                borderRadius: "8px",
                background: ALNColors.background_very_gray,
              }}
            >
              <DisplayEmail email={props.email.inboundEmail!} isReply={false} />
            </Box>
          )}

          {page === "reply" && (
            <Box
              sx={{
                overflowY: "hidden",
                marginTop: "10px",
                marginBottom: "10px",
                marginLeft: "30px",
                borderRadius: "8px",
                background: ALNColors.background_very_gray,
              }}
            >
              <DisplayEmail email={props.email.reply!} isReply={true} />
            </Box>
          )}

          {page === "details" && (
            <Box
              sx={{ 
                overflowY: "hidden",
                marginTop: "10px",
                marginBottom: "10px",
                marginLeft: "30px",
                borderRadius: "8px",
                background: ALNColors.background_very_gray,
              }}
            >
              {props.email.debugInfo && (
                <Box
                  sx={{
                    padding: '30px',
                    paddingLeft: "45px",
                  }}
                >
                  <ALNLabel variant="h5" sx={{ color: ALNColors.black, border: "none", marginBottom: "8px", fontWeight: "600" }}>
                    Details:
                  </ALNLabel>
                  <ALNLabel
                    sx={{ 
                      display: 'block', 
                      fontFamily: 'monospace', 
                      whiteSpace: "pre-wrap", 
                      wordWrap: "break-word",
                    }}
                  >
                    {JSON.stringify(props.email.debugInfo, null, 2)}
                  </ALNLabel>
                </Box>
              )}
            </Box>
          )}

          {props.addedTab && page === "added" && (
            <Box>
              {props.addedTab!(props.email)}
            </Box>
          )}

          {openParseTool && (
            <Box
              sx={{ 
                  position: "fixed",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: ALNColors.black_semi_transparent ,
                  zIndex: 1300,
              }}
            >
              <Box
                padding="20px"
                borderRadius="8px"
                boxShadow={3}
                width={"80%"}
                height={"80%"}
                sx={{ backgroundColor: ALNColors.white }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <ALNLabel variant="h6" sx={{ mb: '0.35em' }}>
                    Email Parse Test Tool
                  </ALNLabel>
                  <CloseIcon
                    style={{
                      marginLeft: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setOpenParseTool(false)}
                  />
                </Box>
                <Box
                  sx={{
                    paddingTop: "20px"
                  }}
                >
                  <DialogEmailParseTool emailData={props.email.inboundEmail!} />
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {/* REASONS UNABLE BUTTON */}
        <Box
          sx={{
            marginTop: "30px",
            marginLeft: "50px",
          }}
        >
          
              <Box>
                <ALNLabel
                  sx={{
                    marginTop: "20px",
                    marginBottom: "16px",
                    paddingLeft: "77px",
                    paddingRight: "28px",
                    paddingTop: "12px",
                    borderRadius: '8px',
                    bgcolor: ALNColors.primary_btn,
                    fontFamily: "OpenSans",
                    fontSize: '15px',
                    fontWeight: "fontWeightMedium",
                    lineHeight: '24px',
                    letterSpacing: "0.1px",
                    textAlign: "left",
                    color: ALNColors.white,
                    width: "279px",
                    height: "35px",
                  }}
                >
                  REASONS UNABLE TO PROCESS
                </ALNLabel>
                {props.email.cannotReplyReason &&
                  props.email.cannotReplyReason.length !== 0 && (
                  <Box>
                    {props.email.cannotReplyReason.map(
                      (line: string | number, index) => (
                        <Box key={index}
                          sx={{
                            display: "flex",
                            paddingTop: "30px",
                            paddingBottom: "30px",
                            borderBottom: `2px solid ${ALNColors.border_gray}`,
                          }}
                        >
                          <AlertIcon
                            width="25px"
                            height="25px"
                          />
                          <ALNLabel
                            sx={{
                              fontFamily: "OpenSans",
                              fontSize: '14px',
                              lineHeight: '21px',
                              fontWeight: 600,
                              letterSpacing: 0.02,
                              textAlign: 'left',
                              color: ALNColors.green_very_dark,
                              textTransform: 'uppercase',
                              paddingLeft: "10px",
                            }}
                          >
                            {line}
                          </ALNLabel>
                        </Box>
                      ),
                    )}
                  </Box>
                )}
              </Box>


          {props.email.emailStatus === EmailStatus.IssueReported && (
            <Box
              sx={{
                marginTop: "5px"
              }}
            >
              <ALNLabel sx={{ fontSize: "16px", lineHeight: "21px", fontWeight: 600, color: ALNColors.black_gray, textAlign: "center"}}>Reported Issue:</ALNLabel>
              <Box
                sx={{
                  display: "flex",
                  paddingTop: "30px",
                  paddingBottom: "30px",
                  borderBottom: `2px solid ${ALNColors.border_gray}`,
                }}
              >
                {props.email.issuesNotes !== "" && 
                  <AlertIcon
                    width="25px"
                    height="25px"
                  />
                }
                <ALNLabel
                  sx={{
                    fontFamily: "OpenSans",
                    fontSize: '14px',
                    lineHeight: '21px',
                    fontWeight: 600,
                    letterSpacing: 0.02,
                    textAlign: 'left',
                    color: ALNColors.green_very_dark,
                    textTransform: 'uppercase',
                    paddingLeft: "10px",
                  }}
                >
                  {props.email.issuesNotes}
                </ALNLabel>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

    </Box>
  );
}

export default EmailMainPart;
