import React from 'react';
import { TextField } from '@mui/material';

interface ALNTextFieldProps {
    label?: string;
    type?: string | undefined;
    variant?: 'filled' | 'outlined' | 'standard';
    sx?: object;
    rows?: string | number | undefined;
    value: string | number | undefined;
    onChange: (value: string) => void;
  }
  
  function ALNTextField ({
    label,
    type,
    variant = 'outlined',
    sx,
    rows=undefined,
    value,
    onChange,
  }: ALNTextFieldProps) {
    return (
      <TextField
          label={label}
          type={type}
          variant={variant}
          sx={sx}
          rows={rows}
          multiline={rows !== undefined}
          value={value}
          onChange={(e) => onChange(e.target.value)}
      />
    );
  };
  
  export default ALNTextField;
