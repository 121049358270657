import { Close } from "@mui/icons-material";
import { Button } from "@mui/material"
import { ALNColors } from "../../../utils/color";

interface ALNCancelBtnProps {
  onClick: () => void;
}

function ALNCancelBtn ({ onClick }: ALNCancelBtnProps) {
   return (
        <Button
            variant="text"
            onClick={onClick}
            sx={{
            color: ALNColors.white,
            width: "38px",
            height: "38px",
            minWidth: "0",
            padding: "0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            }}
        >
            <Close />
        </Button>
    );
} 

export default ALNCancelBtn;