export const ALNColors = {
    primary: "#00a218", // Green
    secondary: "#6c757d", // Gray
    success: "#198754", // Green (success)
    error: "#f44336", // Red (error)
    background: "#fbfbff", // Light Gray
    primary_btn: "#4C4E8D", // Indigo
    red: "#ff0000", // Red
    red_light: "#f8d7da", // Light Red
    red_dark: "#721c24", // Dark Red
    red_very: "#D32F2F",
    red_medium_shade :"#ff7043",
    gray: "#999999", // Medium Gray
    gray_dark: "#b3b3b3", // Dark Gray
    gray_darker: "#666666", // Darker Gray
    gray_very_light: "#e6e6e6", // Very Light Gray
    gray_medium: "#BDBDBD", // Medium Gray (Divider)
    gray_medium_light: "#cfd8dc",
    gray_very_dark: "#4d4d4d", // Very Dark Gray
    gray_very_ligher: "#E0E2E4",
    black: "#000000", // Black
    black_opacity: "#00000014", // Black (with opacity)
    black_semi_transparent: 'rgba(0, 0, 0, 0.5)', // Semi-transparent Black
    black_light_grayish: "#C1C4C9", // Light Grayish Black
    black_white: "#8E8E8F",
    green: "#051512", // Dark Green
    green_light: "#6FB391",
    green_very_dark: "#37300E",
    green_very_light: "#E2EDE8",
    green_medium_light: "#a5d6a7",
    yellow: "#e2cb00", // Yellow
    yellow_very_dark: "#FF8C4B",
    pink_light: "#f8e7e7", // Light Pink
    indigo: "#3A4188", // Indigo (Mail)
    blue: "#2DC4A9", // Teal
    blue_light: "#3f51b5",
    white: "#ffffff", // White
    white_blue: "#3A2178", // Deep Blue
    white_green: "#F7F8F8", // Off-White
    white_black: "#4caf50", // Green
    white_gray: "#eff2f5", // Light Gray (White-Gray)
    white_medium_gray: "#EEEEEE",
    white_very_gray: "#F4F4F8",
    white_less: "#DCDCDC", // Light Gray
    lightgray: "#d3d3d3", // Light Gray
    ligthgray_dark: "#333333", // Dark Gray
    primary_input_border: "#DFE7E5", // Light Gray
    email_auto: "#8CB8A2",
    email_couldnotrespond: "#DCCA9F",
    email_manual: "#4D4E8D",
    email_withissue: "#EF6341",
    email_ignored: "#636C78",
    border_gray: "#E4E4E4",
    border_very_gray: "#334155",
    pink_very_light_shade: "#f8bbd0",
    brown_light_shade: "#ffcc80",
    background_gray: "#00000033",
    background_very_gray: "#F6F6F9",
    backgroud_very_light_gray: "#EDEDF4",
    backgroud_transparent: "#4D4E8D1A",
    black_gray: "#808080",
}