import React from "react";
import { Box } from "@mui/material";
import { ReactComponent as ArrowBackIcon } from "../assets/images/arrowback-icon.svg";
import ALNLabel from "../components/label/ALNLabel";
import { ALNColors } from "../utils/color";
import { Global, Pages } from "../Global";
  
function Settings() {
return (
    <Box
    sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "25px 50px",
        background: "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)"
    }}
    >
    <ALNLabel sx={{fontSize: "10px", lineHeight: "12px", fontWeight: 500, width: "fit-content"}}>
        Settings
    </ALNLabel>
    <Box
        sx={{
        background: ALNColors.white,
        padding: "25px 25px", 
        borderRadius : "16px",
        border: "1px solid "+ALNColors.background_gray,
        height: 'calc(100vh - 170px)',
        }}
    >
        <Box
        sx={{
            flex: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            paddtingTop: "0px",
        }}
        >
        <ArrowBackIcon
        style={{
            marginRight: "14px",
            cursor: "pointer",
            paddingTop: "11px"
        }}
        onClick={() => {
            Global.getGlobal().CurrentPage = Pages.Dashboard;
        }}
        />
        <ALNLabel sx={{ fontSize: "24px", paddingTop: "10px", lineHeight: "18px", fontWeight: 700 }}>
            Settings
        </ALNLabel>
        </Box>
    </Box>
    </Box>
);
};

export default Settings;
  