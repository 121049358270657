import React from 'react';
import { ToggleButtonGroup, ToggleButton } from '@mui/material'; 

interface ALNToggleBtnProps {
  active?: boolean;
  firstToggle?: string | undefined;
  secondToggle?: string | undefined;
  aria_label?: string | undefined;
  aria_label_first?: string | undefined;
  aria_label_second?: string | undefined;
  sx?:object | undefined;
  onChange: (value: string) => void;
}

function ALNToggleBtn({ 
  active,
  firstToggle="", 
  secondToggle="",
  aria_label,
  aria_label_first,
  aria_label_second,
  sx,
  onChange 
}: ALNToggleBtnProps) {
  return (
    <ToggleButtonGroup
      value={active ? `${firstToggle}` : `${secondToggle}`} 
      exclusive
      sx={sx}
      aria-label={aria_label}
      onChange={(event: any, newValue: any) => {
        if (newValue !== null) {
          onChange(newValue);
        }
      }}
    >
      <ToggleButton
        aria-label={aria_label_first}
        value={firstToggle}
      >
        {firstToggle.toUpperCase()}
      </ToggleButton>
      <ToggleButton
        aria-label={aria_label_second}
        value={secondToggle}
      >
        {secondToggle.toUpperCase()}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ALNToggleBtn;
