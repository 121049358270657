import React from "react";
import { Box } from "@mui/material";
import { Email, EmailStatus } from "../../../services/models";
import IssueReported from "./IssueReported";
import AutoResponded from "./AutoResponded";
import Ignored from "./Ignored";
import ManuallyResponded from "./ManuallyResponded";
import CouldNotRespondLayer from "./CouldNotRespondLayer";
import ALNLabel from "../../../components/label/ALNLabel";
import { ALNColors } from "../../../utils/color";

export function EmailEditLayer(props: {
  email: Email;
  onClose: () => void;
}): JSX.Element {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <ALNLabel
        sx={{
          fontSize: "10px",
          lineHeight: "12px",
          fontWeight: 500,
          width: "fit-content",
        }}
      >
        Email &gt; replyaitest.dev &gt; Email: Quote Request
      </ALNLabel>
      <Box
        sx={{
          background: ALNColors.white,
          borderRadius: "16px",
          paddingLeft: "18px",
          paddingRight: "18px",
          paddingTop: "14px",
          paddingBottom: "14px",
        }}
      >
        {props.email.emailStatus === EmailStatus.IssueReported && (
          <IssueReported emailData={props.email} onClose={props.onClose} />
        )}
        {props.email.emailStatus === EmailStatus.AutoProcessed && (
          <AutoResponded emailData={props.email} onClose={props.onClose} />
        )}
        {props.email.emailStatus === EmailStatus.Ignored && (
          <Ignored emailData={props.email} onClose={props.onClose} />
        )}
        {props.email.emailStatus === EmailStatus.ManualProcessed && (
          <ManuallyResponded
            emailData={props.email}
            onClose={props.onClose}
          />
        )}
        {props.email.emailStatus === EmailStatus.NotProcessed && (
          <CouldNotRespondLayer
            emailData={props.email}
            onClose={props.onClose}
          />
        )}
      </Box>
    </Box>
  );
}

export default EmailEditLayer;
