import React from 'react';
import { FormControlLabel } from '@mui/material';
import { ALNColors } from '../../../utils/color';

interface ALNFormControlLabelProps {
  label: string;
  control: React.ReactElement;
  labelPlacement?: "start" | "end" | "top" | "bottom";
}

function ALNFormControlLabel ({ label, control, labelPlacement }: ALNFormControlLabelProps) {
  return (
    <FormControlLabel
      control={control}
      label={label}
      sx={{
        fontFamily: 'OpenSans, sans-serif',
        fontSize: '15px',
        fontWeight: 600,
        lineHeight: '24px',
        color: ALNColors.black,
        letterSpacing: 0.02,
        margin: 0,
        justifyContent: 'flex-end',
        gap: '12px',
        '&:not(:last-child)': {
          marginBottom: '16px',
        },
        '.MuiFormControlLabel-label': {
          fontFamily: 'OpenSans, sans-serif',
          fontSize: '15px',
          fontWeight: 600,
          lineHeight: '27px',
          color: ALNColors.black,
          letterSpacing: 0.02,
          margin: 0,
        },
      }}
      labelPlacement={labelPlacement || 'end'}
    />
  );
};

export default ALNFormControlLabel;
