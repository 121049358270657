import React from "react";
import { Email } from "../../../services/models/Email";
import { Box } from "@mui/material";
import EmailMainPart from "../../../components/EmailMainPart";
import { ALNColors } from "../../../utils/color";
import ALNLabel from "../../../components/label/ALNLabel";
interface IssueReportedProps {
  emailData: Email;
  onClose: () => void;
}

function IssueReported(props: IssueReportedProps) {
  return (
    <Box
      sx={{
        flex: 1,
      }}
    >
      <EmailMainPart
        email={props.emailData}
        onClose={props.onClose}
        addedTabTitle="Resolve"
        addedTab={() => {
          return (
            <Box 
              sx={{
                marginTop: '12px',
                marginLeft: "30px",
                padding: "30px",
                borderRadius: "8px",
                background: ALNColors.background_very_gray,
              }}
            >
              <ALNLabel variant="h5" sx={{ color: ALNColors.black, border: "none", marginBottom: "8px", fontWeight: "600" }}>
                Notes
              </ALNLabel>

              {props.emailData.annotations &&
                props.emailData.annotations.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <Box mb={2}>
                    </Box>
                  </React.Fragment>
                ))}
            </Box>
          );
        }}
      />
    </Box>
  );
}

export default IssueReported;
