import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Services } from "../Services";
import { Global, Pages } from "../Global";

import MailIcon from "@mui/icons-material/Mail";
import { TimePeriod } from "../services/models/TimePeriod";
import { MailBox } from "../services/models/MailBox";
import ALNLabel from "../components/label/ALNLabel";
import ALNMultiSelect from "../components/form/ALNSelect/ALNMultiSelect";
import { ALNColors } from "../utils/color";
import {
  ProductBreakdownSort,
  UserBreakdown,
} from "../services/index";
import { EmailStatus } from "../services/index";

function Dashboard_Users() {
  const [emailStatus, setEmailStatus] = useState<EmailStatus[]>(['NotProcessed', 'AutoProcessed']);
  const handleTagsChange = (event: any, newValues: any) => {
    setEmailStatus(newValues);
  };

  const [dashboardData, setDashboardData] = useState<
    UserBreakdown[] | undefined
  >();

  const [sort, setSort] = useState<ProductBreakdownSort>(
    ProductBreakdownSort.Count,
  );

  const [timePeriod, setTimePeriod] = useState<TimePeriod>(TimePeriod.Day);
  const [mailbox, setMailbox] = useState<string>(
    Global.getGlobal().CurrentMailbox ?? "",
  );
  const [allMailboxes, setAllMailboxes] = useState<string[]>([]);

  const token = Global.getJwtToken();

  useEffect(() => {
    Services.mailBoxService
      .getMailBoxes(Global.getJwtToken()!)
      .then((mailboxes: MailBox[]) => {
        setAllMailboxes(mailboxes.map((mailbox) => mailbox.emailAddress!));
      });
  }, [token]);

  useEffect(() => {
    Services.dashboardService
      .getUserBreakdown(
        Global.getJwtToken()!,
        {
          timePeriod: timePeriod,
          sortBy: sort,
          reason: undefined,
          status: emailStatus,
          mailbox: mailbox
        }
      )
      .then((data) => {
        setDashboardData(data);
      });
  }, [timePeriod, mailbox, sort, emailStatus]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        padding: "25px 50px",
        background:
          "radial-gradient(83.46% 103.4% at 30.48% 32.62%, rgba(77, 78, 141, 0.15) 0%, rgba(237, 237, 244, 0.15) 100%)",
      }}
    >
      <ALNLabel
        sx={{
          fontSize: "10px",
          lineHeight: "12px",
          fontWeight: 500,
          width: "fit-content",
        }}
      >
        Dashboard &gt; Users
      </ALNLabel>
      <ALNLabel
        sx={{
          fontSize: "40px",
          lineHeight: "50px",
          fontWeight: 400,
          width: "fit-content",
        }}
      >
        Users
      </ALNLabel>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"start"}
        sx={{
          background: ALNColors.white,
          padding: "16px",
          borderRadius: "16px",
          border: "1px solid " + ALNColors.background_gray,
          flexGrow: 1,
          height: "calc(100vh - 200px)",
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box sx={{ minWidth: 200, display: "flex", alignItems: "center" }}>
            <MailIcon
              sx={{
                marginRight: 1,
                marginLeft: 5,
                color: ALNColors.indigo,
                alignItems: "baseline",
              }}
            />
            <FormControl
              variant="standard"
              sx={{ minWidth: 300, color: ALNColors.indigo }}
            >
              <InputLabel id="mailbox-select-label">Mailbox</InputLabel>
              <Select
                labelId="mailbox-select-label"
                id="mailbox-select"
                value={mailbox}
                label="Mailbox"
                onChange={(event) => {
                  Global.getGlobal().CurrentMailbox = event.target.value;
                  setMailbox(event.target.value);
                }}
              >
                {allMailboxes.map((mailbox) => (
                  <MenuItem key={mailbox} value={mailbox}>
                    {mailbox}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <ToggleButtonGroup
            value={timePeriod}
            exclusive
            onChange={(event, newTimePeriod) => {
              if (newTimePeriod !== null) {
                setTimePeriod(newTimePeriod);
              }
            }}
            aria-label="time period"
            sx={{
              minWidth: 500,
              justifyContent: "stretch",
              color: ALNColors.primary,
            }}
          >
            {Object.values(TimePeriod).map((period) => (
              <ToggleButton
                key={period}
                value={period}
                aria-label={period}
                sx={{
                  minWidth: 150,
                  border: "none",
                  "&.Mui-selected": {
                    backgroundColor: ALNColors.indigo,
                    color: ALNColors.white,
                    "&:hover": {
                      backgroundColor: ALNColors.white_blue,
                    },
                  },
                }}
              >
                {period}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          padding={"10px"}
          marginTop={"10px"}
          borderTop={"1px solid #e0e0e0"}
          borderBottom={"1px solid #e0e0e0"}
        >
          <FormControl variant="standard" sx={{ minWidth: 300 }}>
            <InputLabel id="sort-select-label">Sort By</InputLabel>
            <Select
              labelId="sort-select-label"
              id="sort-select"
              value={sort}
              label="Sort By"
              onChange={(event) =>
                setSort(event.target.value as ProductBreakdownSort)
              }
            >
              {Object.values(ProductBreakdownSort).map((sortOption) => (
                <MenuItem key={sortOption} value={sortOption}>
                  {sortOption}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl variant="standard" sx={{ minWidth: 300 }}>
            <ALNMultiSelect 
              onChange={(event: any, newValues: any) => {
                handleTagsChange(event, newValues)
              }}
            />
          </FormControl>
        </Box>
        {dashboardData && (
          <Box
            sx={{
              overflow: "auto",
              maxHeight: "80vh",
            }}
            mt={2}
          >
            <Box
              sx={{
                overflow: "auto",
                maxHeight: "80vh",
              }}
              mt={2}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                p={1}
                bgcolor={ALNColors.gray_very_dark}
                color={ALNColors.white}
              >
                <Box width="40%">User</Box>
                <Box
                  width="20%"
                  onClick={() => setSort("Quantity Requested")}
                  sx={{
                    fontWeight:
                      sort === "Quantity Requested" ? "bold" : "normal",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Quantity
                </Box>
                <Box
                  width="20%"
                  onClick={() => setSort("Count")}
                  sx={{
                    fontWeight: sort === "Count" ? "bold" : "normal",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Email Count
                </Box>
                <Box
                  width="20%"
                  onClick={() => setSort("Value")}
                  sx={{
                    fontWeight: sort === "Value" ? "bold" : "normal",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  Value
                </Box>
              </Box>
              {dashboardData.map((item, index) => (
                <Box
                  key={index}
                  display="flex"
                  justifyContent="space-between"
                  p={1}
                  bgcolor={index % 2 === 0 ? ALNColors.gray : ALNColors.white}
                >
                  <Box
                    width="40%"
                    onClick={() => {
                      Global.getGlobal().lastSearchString = item.userRequesting;
                      Global.getGlobal().CurrentPage = Pages.EmailList;
                    }}
                  >
                    <Box
                      sx={{
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      {item.userRequesting}
                    </Box>
                  </Box>
                  <Box width="20%">
                    {item.totalQtyRequested?.toLocaleString()}
                  </Box>
                  <Box width="20%">{item.numberOfRequests}</Box>
                  <Box width="20%">
                    ${" "}
                    {item.totalValue?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Box>
                </Box>
              ))}

              <Divider></Divider>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Dashboard_Users;
