import { PortalInfo } from "./services/models/PortalInfo";
import { Services } from "./Services";
import { User } from "./services/models/User";
import { StateManager } from "./StateManagement/StateManager";

export enum Pages {
  Dashboard = "Dashboard",
  DashboardNotice = "DashboardNotice",
  DashboardReasons = "Dashboard/Reasons",
  DashboardProducts = "Dashboard/Products",
  DashboardCustomerProducts = "Dashboard/CustomerProducts",
  DashboardCustomers = "Dashboard/Customer",
  EmailList = "EmailList",
  MailBoxList = "MailBoxList",
  UserList = "UserList",
  Companies = "CompaniesList",
  EmailAddressRules = "EmailAddressRulesList",
  QuoteTool = "QuoteTool",
  EmailParseTest = "EmailParseTest",
  Support = "Support",
  Settings = "Settings",
}

export class Global {
  lastSearchString: string | undefined = undefined;

  company?: string;
  getBreadcrumbTitle() {
    return this.CurrentPage;
  }
  static getGlobal(): Global {
    return Global._global;
  }

  static _global = new Global();

  private _CurrentPage: Pages = Pages.Dashboard;
  public get CurrentPage(): Pages {
    return this._CurrentPage;
  }
  public set CurrentPage(value: Pages) {
    this._CurrentPage = value;
    StateManager.propertyChanged(Global.getGlobal(), "CurrentPage");
  }

  private _CurrentMailbox: string | undefined = undefined;
  public get CurrentMailbox(): string | undefined {
    return this._CurrentMailbox;
  }
  public set CurrentMailbox(value: string | undefined) {
    let priorMailbox = this._CurrentMailbox;
    this._CurrentMailbox = value;
    if (value !== priorMailbox)
      StateManager.propertyChanged(Global.getGlobal(), "CurrentMailbox");
  }

  user: User | undefined;
  companies: string[] | undefined;
  _jwtToken: string | undefined = undefined;

  static getJwtToken(): string | undefined {
    if (!Global._global._jwtToken) {
      Global._global._jwtToken = localStorage.getItem("token")! ?? undefined;
      if (Global._global._jwtToken) {
        Global.getGlobal().populateLists();
      }
    }

    return Global._global._jwtToken;
  }

  static setJwtToken(token: string | undefined) {
    if (token === Global.getJwtToken()) {
      return;
    }

    if (!token) {
      localStorage.removeItem("token");
      Global.getGlobal()._jwtToken = undefined;
      Global.getGlobal().CurrentMailbox = undefined;
      Global.getGlobal().clearLists();
    } else {
      localStorage.setItem("token", token);
      Global.getGlobal()._jwtToken = token;

      // triggers a fetch of mailbox
      Global.getGlobal().clearLists();
      Global.getGlobal().CurrentMailbox;
      Global.getGlobal().populateLists();
    }
    StateManager.propertyChanged(Global.getGlobal(), "jwtToken");
  }

  static _portalInfo: PortalInfo | undefined = undefined;
  static async getPortalInfo(): Promise<PortalInfo | undefined> {
    if (this._portalInfo) return this._portalInfo;

    this._portalInfo = await Services.userService.getPortalInfo();
    StateManager.propertyChanged(Global.getGlobal(), "portalInfo");
    return this._portalInfo;
  }

  static async getUser(): Promise<User | undefined> {
    if (Global.getJwtToken() && !Global.getGlobal().user) {
      Global.getGlobal().user = await Services.userService.getMe(
        Global.getJwtToken()!,
      );
    }

    StateManager.propertyChanged(Global.getGlobal(), "user");

    return Global.getGlobal().user;
  }

  static async resetUser() {
    Global.getGlobal().user = undefined;
    await Global.getUser();
  }

  static async getAllCompanies(): Promise<string[] | undefined> {
    if (Global.getJwtToken() && !Global.getGlobal().companies) {
      Global.getGlobal().companies =
        await Services.companyService.getMyCompanies(Global.getJwtToken()!);
    }

    return Global.getGlobal().companies;
  }

  static async resetCompanyList() {
    Global.getGlobal().companies = undefined;
    await Global.getAllCompanies();
  }

  populateLists(): void {
    if (Global.getJwtToken() === undefined) return;

    if (!this.emailGeneratorPluginList)
      Services.mailBoxService
        .getEmailGeneratorPluginList(Global.getJwtToken()!)
        .then((l) => (this.emailGeneratorPluginList = l));
    if (!this.emailParserPluginList)
      Services.mailBoxService
        .getEmailParserPluginList(Global.getJwtToken()!)
        .then((l) => (this.emailParserPluginList = l));
    if (!this.emailInterfacePluginList)
      Services.mailBoxService
        .getEmailInterfacePluginList(Global.getJwtToken()!)
        .then((l) => (this.emailInterfacePluginList = l));
    if (!this.erpInterfacePluginList)
      Services.mailBoxService
        .getERPSytemInterfacePluginList(Global.getJwtToken()!)
        .then((l) => (this.erpInterfacePluginList = l));

    Services.userService
      .getUserCompany(Global._global._jwtToken!)
      .then((company) => {
        Global._global.company = company;
      })
      .catch((e) => {
        console.error(e);
        Global.setJwtToken(undefined);
      });

    Services.userService
      .getMe(Global._global._jwtToken!)
      .then((user) => {
        Global._global.user = user;
        StateManager.propertyChanged(Global._global, "user");
      })
      .catch((e) => {
        console.error(e);
        Global.setJwtToken(undefined);
      });

    Services.mailBoxService
      .getMailBoxes(Global.getJwtToken()!)
      .then((mailBoxes: any) => {
        Global.getGlobal().CurrentMailbox = mailBoxes[0]?.emailAddress;
      })
      .catch((e: any) => {
        console.error(e);
      });
  }

  clearLists(): void {
    this.emailGeneratorPluginList = undefined;
    this.emailParserPluginList = undefined;
    this.emailInterfacePluginList = undefined;
    this.erpInterfacePluginList = undefined;
    this.user = undefined;
    this.company = undefined;
    this.CurrentMailbox = undefined;
  }

  emailGeneratorPluginList: string[] | undefined = undefined;
  emailParserPluginList: string[] | undefined = undefined;
  emailInterfacePluginList: string[] | undefined = undefined;
  erpInterfacePluginList: string[] | undefined = undefined;
}
