import React from 'react';
import { Modal, Box } from '@mui/material';
import { ALNColors } from '../../utils/color';
interface ALNModalProps {
  children?: React.ReactNode,
  open: boolean;
  onClose: () => void;
}
function ALNModal ({children, open, onClose}: ALNModalProps) {
  return(
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box
        sx={{
          borderRadius: 2,
          backgroundColor: ALNColors.white,
          width: 470,
          outline: 'none'
        }} 
      >
        {children}
      </Box>
    </Modal>
  )
}

export default ALNModal;