import React from "react";
import { Email } from "../../../services/models/Email";
import EmailMainPart from "../../../components/EmailMainPart";
import { Box } from "@mui/material";
import { ALNColors } from "../../../utils/color";
import ALNLabel from "../../../components/label/ALNLabel";

interface IgnoredProps {
  emailData: Email;
  onClose: () => void;
}

function Ignored(props: IgnoredProps) {
  return (
    <Box
      sx={{
        borderRadius: 2,
        backgroundColor: ALNColors.white,
        flex: 1,
      }}
    >
       <Box>
        {/* <ALNLabel 
          sx={{
            fontFamily: "'OpenSans'",
            fontSize: "18px",
            fontWeight: 600,
            lineHeight: "27px",
            letterSpacing: "0.02em",
            textAlign: "left",
            color: ALNColors.gray_darker,
            paddingLeft: "12px",
          }}
        >Deleted</ALNLabel> */}
        <ALNLabel 
          sx={{
            fontFamily: "'OpenSans'",
            fontSize: "16px",
            fontWeight: 450,
            lineHeight: "24px",
            letterSpacing: "0.02em",
            textAlign: "left",
            color: ALNColors.gray,
            marginTop: "6px",
            paddingLeft: "12px",
          }}
        >
          Deleted By: <span style={{
            fontFamily: "'OpenSans'",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "24px",
            letterSpacing: "0.02em",
            textAlign: "left",
            color: ALNColors.gray_darker
          }}>{props.emailData.modifiedBy}</span>
        </ALNLabel>
      </Box>
      <EmailMainPart email={props.emailData} onClose={props.onClose} />
    </Box>
  );
}

export default Ignored;
