import React from 'react';
import Button from '@mui/material/Button';

interface ALNBtnProps {
  variant?: 'contained' | 'outlined' | 'text' | undefined;
  sx?: object | undefined;
  type?: "button" | "submit" | "reset" | undefined;
  children? : React.ReactNode;
  onClick: () => void;
}

function ALNBtn ({ variant, sx, type, children, onClick }: ALNBtnProps) {
  return (
    <Button
      variant={variant}
      sx={sx}
      type={type}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}
export default ALNBtn;