import { Box } from "@mui/material";
import { MailBox } from "../../services/models";
import deleteIcon from "../../assets/images/material-symbols_delete.svg";
import editIcon from "../../assets/images/tabler_edit.svg";
import { ALNColors } from "../../utils/color";
import ALNItem from "../../components/form/ALNItem/ALNItem"
import ALNIconBtn from "../../components/form/ALNBtn/ALNIconBtn"

type MailboxListItemProps = {
  mailbox: MailBox;
  onDelete: (emailAddress: string) => void;
  openEditModal: (mailbox: MailBox) => void;
};

export function MailboxListItem ({ mailbox, onDelete, openEditModal }: MailboxListItemProps) {
  return (
    <Box  
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: '20px',
        py:'12px',
        borderRadius: '4px',
        bgcolor: ALNColors.white,
        boxShadow: `0px 1px 1px ${ALNColors.black_opacity}`,
        cursor: 'pointer',
        mb: '16px'
      }}
    >
      <Box>
        <ALNItem firstName = { mailbox.emailAddress } lastName = "" />
      </Box>
      <Box>
        <ALNIconBtn obj = { mailbox } onClick = {() => openEditModal(mailbox)} src = { editIcon } alt = "Edit" />
        <ALNIconBtn obj = { mailbox } onClick = {() => onDelete(mailbox.emailAddress ?? "")} src = { deleteIcon } alt = "Delete" />
      </Box>
    </Box>
  );
};
