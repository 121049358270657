/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DashboardQueryParms,
  DashboardResult,
  GetParseReasons200ResponseInner,
  NotProcessedReason,
  ProductBreakdown,
  TimePeriod,
  UserBreakdown,
  UserProductBreakdown,
} from '../models/index';
import {
    DashboardQueryParmsFromJSON,
    DashboardQueryParmsToJSON,
    DashboardResultFromJSON,
    DashboardResultToJSON,
    GetParseReasons200ResponseInnerFromJSON,
    GetParseReasons200ResponseInnerToJSON,
    NotProcessedReasonFromJSON,
    NotProcessedReasonToJSON,
    ProductBreakdownFromJSON,
    ProductBreakdownToJSON,
    TimePeriodFromJSON,
    TimePeriodToJSON,
    UserBreakdownFromJSON,
    UserBreakdownToJSON,
    UserProductBreakdownFromJSON,
    UserProductBreakdownToJSON,
} from '../models/index';

export interface GetDashboardDataRequest {
    authorization: string;
    timePeriod: TimePeriod;
    mailboxyName?: string;
}

export interface GetNonProcessedReasonsRequest {
    authorization: string;
    timePeriod: TimePeriod;
    mailboxyName?: string;
}

export interface GetParseReasonsRequest {
    authorization: string;
}

export interface GetProductBreakdownRequest {
    authorization: string;
    dashboardQueryParms: DashboardQueryParms;
}

export interface GetUserBreakdownRequest {
    authorization: string;
    dashboardQueryParms: DashboardQueryParms;
}

export interface GetUserProductBreakdownRequest {
    authorization: string;
    dashboardQueryParms: DashboardQueryParms;
}

/**
 * 
 */
export class DashboardApi extends runtime.BaseAPI {

    /**
     */
    async getDashboardDataRaw(requestParameters: GetDashboardDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardResult>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getDashboardData().'
            );
        }

        if (requestParameters['timePeriod'] == null) {
            throw new runtime.RequiredError(
                'timePeriod',
                'Required parameter "timePeriod" was null or undefined when calling getDashboardData().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['timePeriod'] != null) {
            queryParameters['timePeriod'] = requestParameters['timePeriod'];
        }

        if (requestParameters['mailboxyName'] != null) {
            queryParameters['mailboxyName'] = requestParameters['mailboxyName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/dashboard/getDashboardData`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardResultFromJSON(jsonValue));
    }

    /**
     */
    async getDashboardData(authorization: string, timePeriod: TimePeriod, mailboxyName?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardResult> {
        const response = await this.getDashboardDataRaw({ authorization: authorization, timePeriod: timePeriod, mailboxyName: mailboxyName }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getNonProcessedReasonsRaw(requestParameters: GetNonProcessedReasonsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NotProcessedReason>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getNonProcessedReasons().'
            );
        }

        if (requestParameters['timePeriod'] == null) {
            throw new runtime.RequiredError(
                'timePeriod',
                'Required parameter "timePeriod" was null or undefined when calling getNonProcessedReasons().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['timePeriod'] != null) {
            queryParameters['timePeriod'] = requestParameters['timePeriod'];
        }

        if (requestParameters['mailboxyName'] != null) {
            queryParameters['mailboxyName'] = requestParameters['mailboxyName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/dashboard/getNonProcessedReasons`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NotProcessedReasonFromJSON));
    }

    /**
     */
    async getNonProcessedReasons(authorization: string, timePeriod: TimePeriod, mailboxyName?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NotProcessedReason>> {
        const response = await this.getNonProcessedReasonsRaw({ authorization: authorization, timePeriod: timePeriod, mailboxyName: mailboxyName }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getParseReasonsRaw(requestParameters: GetParseReasonsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetParseReasons200ResponseInner>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getParseReasons().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/dashboard/getParseReasons`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetParseReasons200ResponseInnerFromJSON));
    }

    /**
     */
    async getParseReasons(authorization: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetParseReasons200ResponseInner>> {
        const response = await this.getParseReasonsRaw({ authorization: authorization }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getProductBreakdownRaw(requestParameters: GetProductBreakdownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductBreakdown>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getProductBreakdown().'
            );
        }

        if (requestParameters['dashboardQueryParms'] == null) {
            throw new runtime.RequiredError(
                'dashboardQueryParms',
                'Required parameter "dashboardQueryParms" was null or undefined when calling getProductBreakdown().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/dashboard/getProductBreakdown`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardQueryParmsToJSON(requestParameters['dashboardQueryParms']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductBreakdownFromJSON));
    }

    /**
     */
    async getProductBreakdown(authorization: string, dashboardQueryParms: DashboardQueryParms, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductBreakdown>> {
        const response = await this.getProductBreakdownRaw({ authorization: authorization, dashboardQueryParms: dashboardQueryParms }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserBreakdownRaw(requestParameters: GetUserBreakdownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserBreakdown>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getUserBreakdown().'
            );
        }

        if (requestParameters['dashboardQueryParms'] == null) {
            throw new runtime.RequiredError(
                'dashboardQueryParms',
                'Required parameter "dashboardQueryParms" was null or undefined when calling getUserBreakdown().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/dashboard/getUserBreakdown`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardQueryParmsToJSON(requestParameters['dashboardQueryParms']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserBreakdownFromJSON));
    }

    /**
     */
    async getUserBreakdown(authorization: string, dashboardQueryParms: DashboardQueryParms, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserBreakdown>> {
        const response = await this.getUserBreakdownRaw({ authorization: authorization, dashboardQueryParms: dashboardQueryParms }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserProductBreakdownRaw(requestParameters: GetUserProductBreakdownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserProductBreakdown>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getUserProductBreakdown().'
            );
        }

        if (requestParameters['dashboardQueryParms'] == null) {
            throw new runtime.RequiredError(
                'dashboardQueryParms',
                'Required parameter "dashboardQueryParms" was null or undefined when calling getUserProductBreakdown().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/dashboard/getUserProductBreakdown`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DashboardQueryParmsToJSON(requestParameters['dashboardQueryParms']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserProductBreakdownFromJSON));
    }

    /**
     */
    async getUserProductBreakdown(authorization: string, dashboardQueryParms: DashboardQueryParms, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserProductBreakdown>> {
        const response = await this.getUserProductBreakdownRaw({ authorization: authorization, dashboardQueryParms: dashboardQueryParms }, initOverrides);
        return await response.value();
    }

}
