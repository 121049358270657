/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetParseReasons200ResponseInner
 */
export interface GetParseReasons200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetParseReasons200ResponseInner
     */
    display: string;
    /**
     * 
     * @type {string}
     * @memberof GetParseReasons200ResponseInner
     */
    name: string;
}

/**
 * Check if a given object implements the GetParseReasons200ResponseInner interface.
 */
export function instanceOfGetParseReasons200ResponseInner(value: object): value is GetParseReasons200ResponseInner {
    if (!('display' in value) || value['display'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function GetParseReasons200ResponseInnerFromJSON(json: any): GetParseReasons200ResponseInner {
    return GetParseReasons200ResponseInnerFromJSONTyped(json, false);
}

export function GetParseReasons200ResponseInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetParseReasons200ResponseInner {
    if (json == null) {
        return json;
    }
    return {
        
        'display': json['display'],
        'name': json['name'],
    };
}

export function GetParseReasons200ResponseInnerToJSON(value?: GetParseReasons200ResponseInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'display': value['display'],
        'name': value['name'],
    };
}

