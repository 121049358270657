import React from 'react';
import { Box } from '@mui/material';
interface ALNModalBodyProps {
  children?: React.ReactNode;
  sx?: object | undefined;
};
function ALNModalBody ({ children, sx }: ALNModalBodyProps) {
  return(
    <Box
     sx={sx}
    >
      {children}
    </Box>
  )
}
export default ALNModalBody;